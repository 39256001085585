import { useEffect, useState, useCallback } from 'react';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

interface UseBaseAnswerProps {
  analyticsCategory: string;
}


export const useBaseAnswer = ({ analyticsCategory }: UseBaseAnswerProps) => {

  const [display, setDisplay] = useState(0)

  useEffect(() => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.OPEN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsCategory]);

  const handleNextMessage = useCallback(() => {
    setDisplay((prev) => prev + 1)
  }, [])

  return {
    display,
    handleNextMessage,
  };
}