import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames'

import { cacheImagesCascade } from '@web-solutions/core/utils/images';

import { t } from '../localization';
import { DIMS } from '../capture/shape';
import type { LoadMethod, ImgData } from '../constants';

import { UploadPopup } from './popup';
import { IMAGES } from './images';
import { ReactComponent as Camera } from './icons/camera.svg';
import { ReactComponent as Close } from './icons/close.svg';

import classes from './style.module.scss';

cacheImagesCascade([IMAGES.BG]);

interface FaceReadingUploadProps {
  onSuccess: (data: ImgData, type: LoadMethod) => void;
  onCloseClick: () => void;
  onSkipClick: () => void;
  className?: string;
  flare?: boolean;
}

export const FaceReadingUpload: React.FC<FaceReadingUploadProps> = ({
  className,
  flare = true,
  onSuccess,
  onCloseClick,
  onSkipClick,
}) => {
  const location = useLocation();
  const error = !!location.state?.src;
  const tKey = `upload.${error ? 'error_find_face' : 'choose_image'}`;

  return (
    <div className={classNames(classes.wrapper, className)}>
      {error ?
        (
          <img src={location.state?.src} alt="face" width={DIMS.width} />
        ) :
        (
          <div className={classes.header}>
            <Close />
            <span className={classes.header__title}>{t(`${tKey}.header_title`)}</span>
            <Camera />
          </div>
        )}
      <UploadPopup
        flare={flare}
        onSuccess={onSuccess}
        onCloseClick={onCloseClick}
        onSkipClick={onSkipClick}
        tKey={tKey}
        isErrorPage={error}
      />
    </div>
  );
};
