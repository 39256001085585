import { useContext, createContext } from 'react';

export interface TerminateContext {
  logo?: React.ReactNode;
}

const terminateContext = createContext({} as TerminateContext);
export const TerminateContextProvider = terminateContext.Provider

export const useTerminateContext = () => {
  const context = useContext(terminateContext);
  return context;
}