import request from '@web-solutions/core/utils/network';

import { getActualMailingFlag } from '@web-solutions/base-app/utils/mailing'

import { GENDER as G, RELATIONSHIP as R } from 'src/constants/profile';

// mapping from app
export const G_IDS = [G.FEMALE, G.MALE, G.NON_BINARY];
export const R_IDS = [R.SOULMATE, R.ENGAGED, R.MARRIED, R.DIFFICULT, R.SINGLE];

export const getPalmReport = () => {
  return request.get('/image-recognition/palm-reading');
}

export const saveFutureGoals = (data) => {
  return request.post('/goals/save', data);
}

export const savePalm = (data) => request.post('/image-recognition/palm-reading?hand_type=left',
  data,
  {
    headers: {
      Accept: "*/*"
    }
  });

export const update = (profile) => request.put('/profile', {
  name: profile.name || 'You',
  gender: G_IDS.includes(profile.gender) ? G_IDS.indexOf(profile.gender) : 0,
  marital_status: R_IDS.includes(profile.relStatus) ? R_IDS.indexOf(profile.relStatus) : 0,
  birthdate: profile.birthDate || '2000-01-01',
  birthtime: profile.birthTime
    ? parseInt(profile.birthTime.split(':')[0], 10) * 60 +
    parseInt(profile.birthTime.split(':')[1], 10)
    : undefined,
  birth_place: profile.birthPlace?.label,
  lat: profile.birthPlace?.lat,
  lon: profile.birthPlace?.lon,
  lang: profile.lang,
  email: profile.email,
  is_unsubscriber: getActualMailingFlag(profile.is_unsubscriber),
});

export const updateProfileParams = (params) => request.post('/profile/set-profile-params', { data: params })