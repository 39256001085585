import dayjs from "dayjs";

export const convertArrToLocaleTime = (arr: string[]) => {
  let time = dayjs().format('LT');
  arr.forEach(() => {
    time = time.replace(/[^:\s@]+/, '@@');
  })
  arr.forEach((i) => {
    time = time.replace(/@@/, i);
  })

  return time
}