import AVA from './ava.webp';
import AVA2X from './ava@2x.webp';
import AVA3X from './ava@3x.webp';

const IMAGES = {
  AVA: {
    src: AVA,
    srcSet: `${AVA}, ${AVA2X} 2x, ${AVA3X} 3x`,
  },
};

export default IMAGES;
