import React from 'react';
import classNames from 'classnames';
import Lottie from 'lottie-react-web';

import { Title, Button, LinkButton } from '@web-solutions/core/ui-elements';
import { cacheImagesCascade } from '@web-solutions/core/utils/images';
//@ts-ignore
import { checkIsAndroidTikTokBrowser } from '@web-solutions/core/utils/common';
import { useTheme } from '@web-solutions/core/hooks/use-theme';

import { t } from '../localization';

import { getTKeyPartByContentMode } from '../utils/getContentMode';

import type { ContentMode } from '../types';

import { ReactComponent as FaceIcon } from './images/face.svg';
import scan from './animations/scan.json';
//@ts-ignore
import video from './animations/Video.mp4'

import classes from './style.module.scss';
import { IMAGES } from './images';

const tKey = 'welcome';

cacheImagesCascade([IMAGES.FACE]);

interface FaceReadingWelcomeProps {
  isSkipAvailable?: boolean;
  isUploadAvailable?: boolean;
  type?: 'image' | 'static' | 'video',
  welcomeContent?: React.ReactNode;
  flare?: boolean;
  onCaptureClick: () => void;
  onUploadClick: () => void;
  onSkipClick: () => void;
  welcomeInstructions?: boolean;
  className?: string;
  contentMode?: ContentMode
}

export const FaceReadingWelcome: React.FC<FaceReadingWelcomeProps> = ({
  className,
  isSkipAvailable,
  isUploadAvailable,
  type = 'image',
  contentMode,
  welcomeContent,
  flare = true,
  onCaptureClick,
  onUploadClick,
  onSkipClick,
  welcomeInstructions
}) => {
  const { isLightTheme } = useTheme();
  const isFixedButton = isSkipAvailable || isUploadAvailable;
  const instructions = t(`${tKey}.instructions`, { returnObjects: true }) as any as string[];

  const withAnimation = type !== 'image'

  const isTikTok = checkIsAndroidTikTokBrowser()

  const content = <>
    {withAnimation ?
      <div className={classes.animation}>
        {type === 'video' ?
          <>
            <video autoPlay loop muted playsInline>
              <source src={video} type="video/mp4" />
            </video>
            <Lottie
              options={{ animationData: scan }}
              style={{ position: 'absolute', zIndex: 100, top: 0, left: 0 }}
            />
          </>
          :
          <img src={IMAGES.FACE.src} srcSet={IMAGES.FACE.srcSet} alt="face" />
        }
      </div>
      :
      <>
        <FaceIcon className={classes.image} />
      </>
    }
    {welcomeInstructions &&
      <div className={classes.instructions}>
        {
          instructions.map((item, index) => (
            <p className={classes.instructions_item_text} key={index}><span >{index + 1}. </span>{item}</p>
          ))
        }
      </div>
    }
    {
      !welcomeInstructions &&
      <div className={classes.subtitle}>{t(`${tKey}${withAnimation ? '.animation' : ''}${getTKeyPartByContentMode(contentMode)}.subtitle`)}</div>
    }
  </>

  return (
    <div className={classNames(classes.wrapper, { [classes.lightTheme]: isLightTheme }, className)}>
      <Title level='h2' className={classes.title}>{t(`${tKey}${withAnimation ? '.animation' : ''}${getTKeyPartByContentMode(contentMode)}.title`)}</Title>
      {welcomeContent ? welcomeContent : content}
      <div className={classes.footer}>
        {isTikTok ?
          <>
            {isUploadAvailable && <Button
              flare={flare}
              title={t(`${tKey}.upload`)}
              onClick={onUploadClick}
              iconRight
              className={classNames({ [classes.button_fixed]: !isFixedButton })}
            />}
            {
              isSkipAvailable && (
                <LinkButton className={classNames(classes.link, classes.skip)} onClick={onSkipClick}>
                  {t(`${tKey}.skip`)}
                </LinkButton>
              )
            }
            <LinkButton className={classes.link} onClick={onCaptureClick}>
              {t(`${tKey}${withAnimation ? '.animation' : ''}.button_title`)}
            </LinkButton>
          </>
          :
          <>
            <Button
              flare={flare}
              title={t(`${tKey}${withAnimation ? '.animation' : ''}.button_title`)}
              onClick={onCaptureClick}
              iconRight
              className={classNames({ [classes.button_fixed]: !isFixedButton })}
            />
            {
              isSkipAvailable && (
                <LinkButton className={classNames(classes.link, classes.skip)} onClick={onSkipClick}>
                  {t(`${tKey}.skip`)}
                </LinkButton>
              )
            }
            {
              isUploadAvailable && (
                <LinkButton className={classes.link} onClick={onUploadClick}>
                  {t(`${tKey}.upload`)}
                </LinkButton>
              )
            }
          </>}
      </div>
    </div >
  );
};
