export const GENDER = {
  FEMALE: 'female',
  MALE: 'male',
  NON_BINARY: 'non-binary',
};

export const RELATIONSHIP = {
  SINGLE: 'single',
  MARRIED: 'married',
  SOULMATE: 'soulmate',
  ENGAGED: 'engaged',
  DIFFICULT: 'difficult',
  BROKE_UP: 'broke_up',
};


export const GOALS = {
  FAMILY: "Family",
  CAREER: "Career",
  HEALTH: "Health",
  MARRIED: "Getting married",
  TRAVELING: "Traveling",
  EDUCATION: "Education",
  FRIENDS: "Friends",
  PARTNER: "Partner",
  SEX: "Sex",
  COMMUNICATION: 'Communication',
  MARRIAGE: 'Marriage',
  COMPATIBILITY: 'Compatibility',
  UNDERSTAND: 'Understand',
  FREE: 'Free',
  NONE: 'none',
  SOULMATE: 'Soulmate',
  HAPPINESS: 'Happiness',
  DISCOVER: 'Discover',
  PERSON: 'Person'
};

export const RELY_ON = {
  DEPENDS: 'depends',
  HEAD: 'head',
  HEART: 'heart'
}
