import { AppLinks } from '@web-solutions/module-attribution'

import { getTheme } from 'core/utils/images';

export const APP_NAME = process.env.REACT_APP_TITLE;

export const PROJECT_SUPPORT_SUBJECT = `[${process.env.REACT_APP_TITLE} WEB] `;

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const HELP_CENTER_LINK = 'https://help.astroline.today'

export const APP_LINKS: AppLinks = {
  ADJUST_LINK: process.env.REACT_APP_ADJUST_LINK,
  ADJUST_TOKEN: process.env.REACT_APP_ADJUST_TOKEN,
  WEB_APP_LINK: process.env.REACT_APP_WEB_APP_URL,
};

export const LINES = {
  LOVE: 'love',
  HEAD: 'head',
  LIFE: 'life',
  FATE: 'fate',
};

const LINES_COLORS_DEFAULT = {
  [LINES.LOVE]: '#F27067',
  [LINES.HEAD]: '#F9D05D',
  [LINES.LIFE]: '#19CEBB',
  [LINES.FATE]: '#C583FA'
};

const LINES_COLORS_DARK = {
  [LINES.LOVE]: '#FF406E',
  [LINES.HEAD]: '#FFE141',
  [LINES.LIFE]: '#50E7B4',
  [LINES.FATE]: '#B67BFF'
};

const handler = {
  get(target: Record<string, string>, prop: string) {
    if (getTheme() === 'dark') {
      return LINES_COLORS_DARK[prop]
    }
    return target[prop];
  },
};

export const LINES_COLORS = new Proxy(LINES_COLORS_DEFAULT, handler);

export const PATHES = {
  palm: "readings",
  chart: "birth-chart",
  compatibility: "compatibility",
  tarot: "tarot",
  "your_day": '',
  horoscope: '',
};

export const PALM_RATIO = window.location.pathname.split('/')[1] === 'chat-flow' ? 1 : 1.5;