import { ReactComponent as Icon } from './icon/icon.svg';

import classes from './style.module.scss';

interface ButtonProps {
  disabled?: boolean;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({ disabled, onClick }) => {
  return <button disabled={disabled} onClick={onClick} className={classes.btn}>
    <Icon />
  </button>
}