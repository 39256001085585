import i18next, { type Resource } from 'i18next';
import { type TransProps } from 'react-i18next';

import { t as baseT, tm as baseTm, T as BaseT, TM as BaseTM, type LString } from '@web-solutions/module-localization';

const resources: Resource = {
  de: require('./de.json'),
  en: require('./en.json'),
  'es-mx': require('./es-mx.json'),
  es: require('./es.json'),
  fr: require('./fr.json'),
  'pt-br': require('./pt-br.json'),
  pt: require('./pt.json'),
  ja: require('./ja.json'),
};

const ns = '@web-solutions/chat-flow';

export function initLocalization(overriding?: any) {
  for (const k in resources) {
    i18next.addResourceBundle(k, ns, resources[k], true, false);
    if (overriding?.[k]) {
      i18next.addResourceBundle(k, ns, overriding[k], true, true);
    }
  }
}


type TProps = Omit<TransProps<any>, "i18n" | "i18nKey">


export const T = ({ k, tOptions, ...other }: { k: string, } & TProps) => {

  return (
    <BaseT
      k={k}
      tOptions={tOptions}
      //@ts-ignore
      ns={ns}
      {...other}
    />
  )
}

export const TM = ({ k, defaultKey = '', tOptions, ...other }: { k?: LString, defaultKey?: string } & TProps) => {

  return (
    <BaseTM
      k={k}
      defaultKey={defaultKey}
      tOptions={tOptions}
      //@ts-ignore
      ns={ns}
      {...other}
    />
  )
}

export function t(key: string, options?: any): string {
  options = options || {};
  if (options.ns === undefined) {
    options.ns = ns;
  }
  return baseT(key, options);
}

export function tm(key: LString | undefined, dk: string, options?: any): string {
  options = options || {};
  if (options.ns === undefined) {
    options.ns = ns;
  }
  return baseTm(key, dk, options);
}
