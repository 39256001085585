//@ts-ignore
export const TIME_HOURS = [...Array(12).keys()].map((v) => ({ value: v + 1, selected: v + 1 === 12 }))

//@ts-ignore
export const TIME_MINUTES = [...Array(60).keys()].map((v) => ({ value: v < 10 ? `0${v}` : v, selected: v === 0 }))

export const TIME = [
  { value: 'AM', selected: true },
  { value: 'PM' },
]

//@ts-ignore
export const DATE_MONTH = [...Array(12).keys()].map((v) => ({ value: v < 9 ? `0${v + 1}` : v + 1 }))

//@ts-ignore
export const DATE_YEARS = [...Array(125).keys()].map((v) => ({ value: v + 1900, })).reverse();