import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import classes from './style.module.scss';

const Autocomplete = ({
  options,
  onSelect,
  optionClassName,
  isEmail
}) => {
  const handleItemClick = useCallback((e, option) => {
    e.preventDefault();
    onSelect(option);
  }, [onSelect]);

  return (
    <ul className={classNames(classes.helper, {[classes.for_email]: isEmail})}>
      {options.map((option) => (
        <li
          key={option.label}
          className={classNames(classes.item, optionClassName)}
        >
          <button
            type="button"
            onClick={(e) => handleItemClick(e, option)}
            className={classes.button}
          >
            {option.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

Autocomplete.propTypes = {
  options:
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  onSelect: PropTypes.func.isRequired,
  optionClassName: PropTypes.any,
};

Autocomplete.defaultProps = {
  optionClassName: null,
};

export default Autocomplete;
