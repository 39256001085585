import { createSelector } from 'reselect';
import queryString from 'query-string';

import Attribution, { LinkTarget } from '@web-solutions/module-attribution';

import { RootState } from '../types';


export const selectAppLink = createSelector(
  (state: RootState) => state.remoteConfig.redirectToAppWithoutSubscription,
  (state: RootState) => state.billing.purchased,
  (state: RootState) => state.remoteConfig.linkTarget,
  (state: RootState, linkTarget?: LinkTarget) => linkTarget,
  (redirectToAppWithoutSubscription, purchased, rcLinkTarget, linkTarget) => {
    const params = queryString.parse(window.location.search);

    let linkTargetByPlatform;
    if (params.platform) {
      linkTargetByPlatform = params.platform === 'web' ? LinkTarget.WEB : LinkTarget.DEFAULT;
    }

    linkTarget = linkTarget || linkTargetByPlatform || rcLinkTarget;
    const source = (redirectToAppWithoutSubscription.enabled && !purchased) || (params.without_sub !== undefined) ? 'quiz' : 'sub';
    return queryString.stringifyUrl({ url: Attribution.getLinkToApp(linkTarget), query: { source } })
  }
);
