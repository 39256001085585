import UAParser from 'ua-parser-js';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const checkIsAndroidTikTokBrowser = () => {
  const parser = new UAParser();
  const browser = parser.getBrowser();
  const os = parser.getOS();
  return browser?.name === 'TikTok' && os?.name === 'Android';
}