import moment from 'moment';
import queryString from 'query-string';

import Mutator from '@magnus/react-native-mutator';

const answers: Record<string, string[]> = {}
const installTime = +(window.localStorage.getItem('__install_time__') || Date.now());

export function trackEvent(event: string, params?: any) {
  if (params?.answer) {
    const answer = String(params?.answer);
    answers[event] = answers[event] || [];
    if (!answers[event].includes(answer)) {
      answers[event].push(String(answer));
    }
  }
}

export function setUserProperty(name: string, value: string) {
  answers[`user_prop_${name}`] = [value];
}

export async function getSubscriptionPrediction(): Promise<{ is_activation: boolean, sure_activation: boolean }> {
  const country = await Mutator.getCountryCode();
  return fetch('https://subtruck.magnus.ms/api/v2/parameters/predict/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': String(process.env.REACT_APP_MAGNUS_TOKEN),
    },
    body: JSON.stringify({
      ...answers,
      install_time: [
        moment(installTime).utc().format('YYYY-MM-DD HH:mm:ss'),
      ],
      timezone: [
        String(-(new Date().getTimezoneOffset() / 60)),
      ],
      utm_source: queryString.parse(window.location.search).utm_source || '',
      balance_param: [
        0.5
      ],
      country: [
        country,
      ],
    })
  })
    .then(r => {
      if (r.ok) {
        return r.json();
      } else {
        throw Error(String(r.status));
      }
    })
    .then(r => r.data);
}