import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { BaseAnswer } from '../base-answer';
import type { Answer, AnswerProcessorParams, BaseAnswerProps } from '../../types';
import { AnswerButton } from '../answer-button';
import { useBaseAnswer } from '../../hooks/use-base-answer';

interface SingleAnswerProps extends BaseAnswerProps {
  data: {
    answer: Answer[];
    skipText?: string;
  };
  onSuccess: (id: AnswerProcessorParams, screen: string) => void;
}

export const SingleAnswer: React.FC<SingleAnswerProps> = ({
  messages,
  postMessages,
  data,
  analyticsCategory,
  withBoost,
  isActive,
  onNextMessage,
  onSuccess,
}) => {
  const { answer } = data;

  const { display, handleNextMessage } = useBaseAnswer({ analyticsCategory });

  const handleSuccess = (id: string) => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS);
    onSuccess({ value: id }, analyticsCategory);
    handleNextMessage();
    onNextMessage && onNextMessage();
  }

  const handleNewMessage = () => {
    handleNextMessage();
    onNextMessage && onNextMessage()
  }

  return <BaseAnswer
    isActive={isActive}
    analyticsCategory={analyticsCategory}
    messages={messages}
    postMessages={postMessages}
    display={display}
    withBoost={withBoost}
    onNextMessage={handleNewMessage}
  >
    {display >= messages.length && <AnswerButton withicon id={answer[0].id} text={answer[0].text} onClick={handleSuccess} />}
  </BaseAnswer>
}