import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { TM, t } from '@web-solutions/module-localization';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { PaymentModalType } from 'core/constants/remote-config';
import { ProductInfo } from 'core/payment/base/product-info';
import TrialReminder from 'core/payment/components/trial-reminder';
import { Title } from 'core/ui-elements';

import InjectedPaymentMethodSwitch from 'core/summary/injected-payment-method/components/switch';

import { PaymentSafe } from 'core/containers/payment-safe';

import type { PaymentModalTitle } from 'core/interfaces/payment-modal';

import PaymentDescription from '../../components/payment-description';
import { TotalPrice } from '../product-info/components/simple-view';
import { getActualPrices } from '../product-info/components/simple-view/utilts';

import classes from './style.module.scss';
import { PaymentMethods, PaymentMethodsProps } from './components/payment-methods';

const tKey = 'core.payment_popup';

interface Props extends Omit<PaymentMethodsProps, 'isSimpleView' | 'isBaseModal'> {
  isOnlyBaseModal?: boolean,
  title?: PaymentModalTitle,
  subtitle?: React.ReactNode,
  isOnlyCard?: boolean,
}

const BasePaymentModalBody: React.FC<Props> = ({
  activeProduct,
  isOnlyBaseModal,
  title,
  subtitle,
  isOnlyCard,
  ...props
}) => {
  const {
    isShowSafeCheckout,
    showTermsAtPaymentModal,
    paymentModalType,
    isTrialPeriodReminder,
    simpleTitleModal,
    totalPriceType,
  } = useSelector(remoteConfigSelector);

  const modalType = isOnlyBaseModal ? PaymentModalType.BASE : paymentModalType;

  const isBaseModal = modalType === PaymentModalType.BASE;
  const isSimpleModal = modalType === PaymentModalType.SIMPLE;
  const isSimpleTitleModal = modalType === PaymentModalType.SIMPLE_TITLE;

  const isSimpleView = isSimpleModal || isSimpleTitleModal;

  const { fullPrice, actualPrice, vat, vatPrice } = getActualPrices({ activeProduct, totalPriceType })

  return (
    <div className={classNames(classes.contentContainer, { [classes.simple]: isSimpleView })}>
      {isSimpleTitleModal && simpleTitleModal?.titleEnabled && (
        <header className={classes.header} style={!simpleTitleModal?.withTotalPriceUnder ? { display: 'contents' } : {}}>
          <Title
            className={classes.title}
            style={(simpleTitleModal?.fontSize ? { fontSize: `${simpleTitleModal.fontSize}px` } : {})}
          >
            {title?.simple_title || <TM k={t(`${tKey}.simple_type.title_default`)} />}
          </Title>
          {simpleTitleModal?.withTotalPriceUnder &&
            <TotalPrice
              className={classes.totalPrice}
              mode={simpleTitleModal?.type}
              vat={vat}
              vatPrice={vatPrice}
              fullPrice={fullPrice}
              actualPrice={actualPrice}
            />
          }
        </header>
      )}
      <div className={classNames(classes.wrap, { [classes.simple_title]: isSimpleTitleModal })}>
        {
          isBaseModal && (
            <>
              <ProductInfo
                product={activeProduct}
                subtitle={subtitle}
                isOnlyCard={isOnlyCard}
              />
              {
                (isTrialPeriodReminder && !activeProduct.isOneTimePurchase) ?
                  <TrialReminder className={classes.reminder} /> :
                  <div className={classes.divider} />
              }
            </>
          )
        }

        {
          isSimpleView && (
            <>
              <InjectedPaymentMethodSwitch isBaseSwitch />
              <ProductInfo
                product={activeProduct}
                subtitle={subtitle}
                isSimpleView={isSimpleView}
                isOnlyCard={isOnlyCard}
              />
              <div className={classes.divider} />
            </>
          )
        }

        <PaymentMethods
          activeProduct={activeProduct}
          isBaseModal={isBaseModal}
          isSimpleView={isSimpleView}
          {...props}
        />

        {(!activeProduct.isOneTimePurchase) && (
          <>
            {isShowSafeCheckout ? <PaymentSafe /> : null}
            {showTermsAtPaymentModal && (
              <PaymentDescription
                activeProduct={activeProduct}
              />
            )}
          </>
        )}
      </div>
    </div >
  );
};


export default BasePaymentModalBody;
