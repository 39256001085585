import { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ANSWER_COMPONENTS } from '../../constans/answer';
import { AnswerProcessorParams, Question } from '../../types';
import { Header } from '../../components/header';
import { t } from '../../../localization';

import classes from './style.module.scss';

const tKey = 'chat_flow';

interface MainProps {
  onFinish: () => void;
  onAnswer: (val: AnswerProcessorParams, screen: string) => void;
  Questions: Question[];
}

export const Main: React.FC<MainProps> = ({ Questions, onFinish, onAnswer }) => {
  const chatbotData = useSelector((state: any) => state.chat.chatbotData);

  const [display, setDisplay] = useState(0)

  const ref = useRef<HTMLDivElement | null>(null);

  const handleNewMessage = () => {
    setTimeout(() => {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }, 100)
  }

  const handleSuccess = useCallback((id: AnswerProcessorParams, screen: string) => {
    onAnswer(id, screen);
    setDisplay((prev) => prev + 1)
  }, [onAnswer])

  return (
    <>
      <Header chatbotData={chatbotData} />
      <div className={classes.wrapper}>
        <div className={classes.system}>{t(`${tKey}.system_message`, { chatbotName: chatbotData?.name })}</div>
        {Questions.map((item, indx) => {
          if (indx > display) {
            return null
          }

          const Answer = ANSWER_COMPONENTS[item.type]

          return <Answer
            key={item.analyticsCategory}
            data={{
              answer: item.data.answers,
              skipText: item.data.skipText,
              answerComponent: item.data.answerComponent,
            }}
            analyticsCategory={item.analyticsCategory}
            messages={item.messages}
            postMessages={item?.postMessages}
            isActive={display === indx}
            withBoost={item?.withBoost}
            onNextMessage={handleNewMessage}
            onSuccess={handleSuccess}
          />
        })}
      </div>
      <div ref={ref} className={classes.anchor} />
    </>
  )
}