import classNames from 'classnames';

import { ReactComponent as SendIcon } from './icons/send.svg';
import classes from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const UserAnswer: React.FC<Props> = ({ children, className }) => {
  return <div className={classNames(classes.wrapper, className)}>
    {children}
    <SendIcon />
  </div>
}