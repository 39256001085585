import React, { useRef, useState, useCallback, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Provider, PaymentSystem } from '@web-solutions/react-billing';
import Analytics from '@web-solutions/module-analytics';

import {
  initOrders,
  handleErrorPurchase,
  switchToReservePaymentSystem,
} from '@web-solutions/core/store/billing/actions';

import {
  selectLoading,
  selectPaymentSystem,
  selectNoFundsOfferEndDate,
  type ProductDetails,
} from '@web-solutions/core/store/billing/selectors';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import { SOLIDGATE_ERROR_CODES } from '@web-solutions/core/constants/errors';

import BasePaymentModal from '@web-solutions/core/payment/base';
import ThreeDSecure from '@web-solutions/core/payment/components/threeDSecure';
import { Preloader } from '@web-solutions/core/ui-elements';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { useShowMercadoPago } from '@web-solutions/core/hooks/use-show-payment-method';

import type { SubscribeOnOneClickParams } from '@web-solutions/core/store/billing/types';
import type { PaymentError } from '@web-solutions/core/interfaces/errors';
import type { OrderDetails, SolidMetadata, Subscription } from '@web-solutions/core/interfaces/billing';
import type { PaymentModalTitle } from '@web-solutions/core/interfaces/payment-modal';

import ErrorPopup from './components/error-popup';
import { SubscribeOnOneClick, type SubscribeOnOneClickRef } from './components/subscribe-on-one-click'

import {
  useGooglePayProcessor,
  useApplePayProcessor,
  useErrorPopupProcessor,
  useCheckRedirectResults,
  usePayPalProcessor,
  usePaymentProcessorBase
} from './hooks';

export interface PaymentProcessorRef {
  showPaymentPopup: () => void;
  closePaymentPopup: () => void;
  processFormData: (data: any) => void;
  subscribeOnOneClick: (data: SubscribeOnOneClickParams) => void;
}

export interface PaymentProcessorProps {
  activeProduct: ProductDetails,
  fullMode?: boolean,
  orderDetails: OrderDetails | null,
  isOnlyBaseModal?: boolean;
  paymentModalTitle?: PaymentModalTitle,
  isOnlyCard?: boolean,
  isOnlyPayPal?: boolean,
  errorPopupWithPayPal?: boolean,
  hidePayPal?: boolean,
  onSuccess: (purchase: Subscription) => void,
  onError?: (error: any) => void,
  onClose?: () => void,
  onSubmit?: () => void;
  onSubscribeOnOneClickError?: (error: PaymentError) => void;
  isPurchaseProgress?: boolean,
  upsaleTrigger?: string,
  orderMetadata?: any;
  orderSolidMetadata?: SolidMetadata;
}

export const PaymentProcessor = React.forwardRef<PaymentProcessorRef, PaymentProcessorProps>(({
  activeProduct,
  fullMode,
  paymentModalTitle,
  upsaleTrigger,
  orderDetails,
  orderMetadata,
  orderSolidMetadata,
  isOnlyBaseModal,
  isOnlyCard,
  isOnlyPayPal,
  isPurchaseProgress,
  hidePayPal,
  errorPopupWithPayPal,
  onSuccess,
  onClose,
  onError,
  onSubmit,
  onSubscribeOnOneClickError,
}, ref) => {
  const dispatch = useDispatch();

  const socRef = useRef<SubscribeOnOneClickRef>(null)

  const [isOpenPaymentPopup, setIsOpenPaymentPopup] = useState(false);

  const isPending = useSelector((state: any) => state.billing.pending || state.billing.orderPending);
  const isLoadingBilling = useSelector(selectLoading);
  const isLoadingApp = useSelector((state: any) => !state.app.loaded);

  const noFundsOfferEndDate = useSelector(selectNoFundsOfferEndDate);

  const {
    noFundsOffer,
    modeCardForm,
    errorPopup,
  } = useRemoteConfig();

  const paymentSystem = useSelector(selectPaymentSystem);

  const showMercadoPago = useShowMercadoPago() && !activeProduct.isOneTimePurchase;

  const {
    errorCode,
    isOpenErrorPopup,
    setIsOpenErrorPopup,
    setErrorCode,
    handleErrorPopupSubmit,
    handleErrorPopupCancel
  } = useErrorPopupProcessor({
    onCancel: () => setIsOpenPaymentPopup(true),
    onSubmit: () => setIsOpenPaymentPopup(false),
    onClose,
  })

  const onCardError = useCallback((error: PaymentError) => {
    const isInsufficientFundsErrorCode = error?.code === SOLIDGATE_ERROR_CODES.INSUFFICIENT_FUNDS;
    const isSONoFundsTriggered = isInsufficientFundsErrorCode && noFundsOffer.enabled && !noFundsOfferEndDate && error?.entity === 'form'

    if (!activeProduct?.isOneTimePurchase && isSONoFundsTriggered) {
      setIsOpenPaymentPopup(false);

      dispatch(handleErrorPurchase(error, { noToast: true }));

      if (error?.paymentSystem === PaymentSystem.SOLIDGATE) {
        dispatch(initOrders());
      }

      onError && onError(error);
      return
    }

    let noToast;

    setErrorCode && setErrorCode(error?.code)

    if (!activeProduct?.isOneTimePurchase && errorPopup?.enabled) {
      Analytics.trackEvent('error_popup', EVENT_ACTION.OPEN, { message: error?.message, paymentSystem: error?.paymentSystem });

      setIsOpenPaymentPopup(false);
      setIsOpenErrorPopup(true);

      if (!isInsufficientFundsErrorCode) {
        dispatch(switchToReservePaymentSystem());
      }

      noToast = true;
    }

    dispatch(handleErrorPurchase(error, { noToast }));

    if (error?.paymentSystem === PaymentSystem.SOLIDGATE) {
      dispatch(initOrders());
    }
  }, [
    activeProduct?.isOneTimePurchase,
    errorPopup.enabled,
    noFundsOffer.enabled,
    noFundsOfferEndDate,
    dispatch,
    onError
  ])

  const {
    formOptions,
    tokenThreeDSecure,
    processFormData,
    handleSubmit,
    handlePaymentSuccess,
    handleCardFormSubmit,
    handleCardPaymentSuccess,
    handleCardPaymentError,
    handle3DTokenExchanged,
    handle3DTokenError,
    handleUserInfoChange
  } = usePaymentProcessorBase({
    activeProduct,
    paymentSystem,
    orderDetails,
    onCardSuccess: () => setIsOpenPaymentPopup(false),
    onCardError,
    onSuccess,
    onError,
    onSubmit,
  })

  useImperativeHandle(ref, () => ({
    showPaymentPopup() {
      setIsOpenPaymentPopup(true);
    },
    closePaymentPopup() {
      setIsOpenPaymentPopup(false);
    },
    processFormData(data: any) {
      processFormData(data);
    },
    subscribeOnOneClick(data: SubscribeOnOneClickParams) {
      socRef.current?.subscribeOnOneClick(data)
    }
  }), [processFormData]);

  useCheckRedirectResults({ handlePaymentSuccess })

  const {
    showApplePay,
    handleApplePayClick,
    handleApplePaySubmit
  } = useApplePayProcessor({
    paymentSystem,
    trigger: upsaleTrigger,
    orderMetadata,
    orderSolidMetadata,
    handleCardPaymentError,
    handlePaymentSuccess,
    handleSubmit
  });

  const {
    showGooglePay,
    handleGooglePayClick,
    handleGooglePaySubmit
  } = useGooglePayProcessor({ handleSubmit });

  const {
    showPayPal,
    handlePayPalSubmit
  } = usePayPalProcessor({
    activeProduct,
    onSubmit: processFormData
  });

  const handleSubscribeOnOneClickError = useCallback((error: PaymentError) => {
    if (onSubscribeOnOneClickError) {
      onSubscribeOnOneClickError(error);
    } else {
      setErrorCode(error?.code)
      setIsOpenErrorPopup(true)
    }
  }, [onSubscribeOnOneClickError]);

  const handleClosePaymentPopup = useCallback(() => {
    Analytics.trackEvent('modal_payment', EVENT_ACTION.CLOSE);

    setIsOpenPaymentPopup(false);

    onClose && onClose();
  }, [onClose]);

  return (
    <>
      {(isLoadingApp || isLoadingBilling) ?
        <Preloader className='PaymentModalPreloader' /> :
        <Provider>
          <BasePaymentModal
            activeProduct={activeProduct}
            title={paymentModalTitle}
            upsaleTrigger={upsaleTrigger}
            options={formOptions}
            fullMode={fullMode}
            showPopup={isOpenPaymentPopup}
            showMercadoPago={showMercadoPago}
            showCard={isOnlyPayPal !== undefined ? !isOnlyPayPal : modeCardForm !== 'disabled'}
            showApplePay={!!showApplePay && !isOnlyCard && !isOnlyPayPal}
            showGooglePay={!!showGooglePay && !isOnlyCard && !isOnlyPayPal}
            showPayPal={showPayPal && !isOnlyCard && !hidePayPal}
            isOnlyCard={isOnlyCard}
            isOnlyBaseModal={isOnlyBaseModal}
            isPurchaseProgress={isPurchaseProgress}
            onClose={handleClosePaymentPopup}
            onSuccess={handleCardPaymentSuccess}
            onError={handleCardPaymentError}
            onSubmit={handleCardFormSubmit}
            onUserInfoChange={handleUserInfoChange}
            onApplePayClick={handleApplePayClick}
            onApplePaySubmit={handleApplePaySubmit}
            onGooglePayClick={handleGooglePayClick}
            onGooglePaySubmit={handleGooglePaySubmit}
            onPayPalSubmit={handlePayPalSubmit}
            onPayPalSuccess={handleCardPaymentSuccess}
            onPayPalError={handleCardPaymentError}
          />
        </Provider>
      }

      {tokenThreeDSecure && (
        <ThreeDSecure
          actionTokenId={tokenThreeDSecure}
          onToken={handle3DTokenExchanged}
          onError={handle3DTokenError}
        />
      )}

      <SubscribeOnOneClick
        ref={socRef}
        onSuccess={onSuccess}
        onError={handleSubscribeOnOneClickError}
      />

      {errorPopup?.enabled && (
        <ErrorPopup
          product={activeProduct}
          statusCode={errorCode}
          buttonTitle={errorPopup.buttonTitle}
          visible={isOpenErrorPopup}
          withPayPal={errorPopupWithPayPal}
          onClose={handleErrorPopupCancel}
          onPayPalSubmit={handlePayPalSubmit}
          onPayPalSuccess={handleCardPaymentSuccess}
          onPayPalError={handleCardPaymentError}
          onSubmit={handleErrorPopupSubmit}
        />
      )}

      {!!isPending && <Preloader className='PaymentModalPreloader' />}
    </>
  );
});
