import React from 'react';
import classNames from 'classnames'

import ErrorPopUp from '@web-solutions/core/containers/error-popup';
import { useTheme } from '@web-solutions/core/hooks/use-theme';

import { t } from '../localization';

import classes from './style.module.scss';

const tKey = 'error_popup';

interface Props {
  visible: boolean;
  message: string;
  onClose: () => void;
  onSkipClick: () => void;
  className?: string;
}

const PopUp: React.FC<Props> = ({
  className,
  visible,
  message,
  onClose,
  onSkipClick,
}) => {
  const { isLightTheme } = useTheme();

  return <ErrorPopUp
    visible={visible}
    classNameOverlay={isLightTheme ? classes.overlay : undefined}
    className={classNames(className, { [classes.wrap]: isLightTheme })}
    title={t(`${tKey}.title`)}
    subTitle={message || t(`${tKey}.subtitle`)}
    btnTitle={t(`${tKey}.btn`)}
    skipTitle={t(`${tKey}.skip`)}
    onClose={onClose}
    onSkipClick={onSkipClick}
  />
};

export default PopUp;
