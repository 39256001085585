import _map from 'lodash/map';
import _filter from 'lodash/filter';
import React, { useMemo } from 'react';

import Autocomplete from './input-autocomplete';

const EMAILS = [
  '@gmail.com',
  '@yahoo.com',
  '@hotmail.com',
  '@icloud.com',
  '@aol.com',
  '@outlook.com',
  '@hotmail.co.uk',
  '@live.com',
  '@yahoo.co.uk',
  '@msn.com',
];

interface EmailAutocompleteProps {
  value: string,
  onSelect: (value: string) => {} | void,
  optionClassName?: string,
}

const EmailAutocomplete: React.FC<EmailAutocompleteProps> = ({
  value,
  ...other
}) => {
  const [pure, domain] = value.split('@');
  const options = useMemo(() => _map(_filter(EMAILS, e => !domain || e.indexOf(domain) === 1), (i: string) => pure + i), [pure, domain]);

  return (
    <Autocomplete
      options={options}
      {...other}
    />
  );
};

export default EmailAutocomplete;
