import React from 'react';

import { Button } from '@web-solutions/core/ui-elements';

//@ts-ignore
import { SUPPORT_EMAIL } from 'src/constants/general';

import { useReturnToApp } from '../../hooks/use-return-to-app';
import { t } from '../../../localization';

import classes from './style.module.scss';

export const TerminateFooter: React.FC = () => {

  const { available, handleReturnAppClick } = useReturnToApp({ category: 'terminate' });

  return (
    <footer className={classes.footer}>
      <div className={classes.footer_container}>
        {t('terminate.footer.title')}
        <br />
        <a href={`mailto:${SUPPORT_EMAIL}`} className={classes.link}>{SUPPORT_EMAIL}</a>
      </div>
      {available && <Button flare className={classes.btn} title={t('terminate.btn')} onClick={handleReturnAppClick} />}
    </footer>
  )
};
