import { type LString } from '@web-solutions/module-localization';

export enum ButtonPlacementType {
  FIXED = 'fixed',
  PLANS = 'plans',
  PAYMENT = 'payment',
};

export enum ButtonBehavior {
  SCROLL = 'scroll',
  MODAL = 'modal',
  NEXT = 'next',
};

export enum CardFormLayout {
  STANDART = 'standart',
  COMPACT = 'compact',
}

export type ButtonConfig = {
  behavior?: ButtonBehavior,
  title?: LString,
  uppercaseTitle?: boolean,
  enabledFlareAnimation?: boolean,
}

export type InterButtonConfig<SummarySection = string> = ButtonConfig & {
  section: SummarySection,
  priceTitle?: LString,
}


export const COUNTRY_VAT: Record<string, { vat: number, name: string }> = {
  at: {
    vat: 20,
    name: 'Austria',
  },
  be: {
    vat: 21,
    name: 'Belgium',
  },
  bg: {
    vat: 20,
    name: 'Bulgaria',
  },
  hr: {
    vat: 25,
    name: 'Croatia',
  },
  cy: {
    vat: 19,
    name: 'Cyprus',
  },
  cz: {
    vat: 21,
    name: 'Czech Republic',
  },
  dk: {
    vat: 25,
    name: 'Denmark',
  },
  ee: {
    vat: 20,
    name: 'Estonia',
  },
  fi: {
    vat: 24,
    name: 'Finland',
  },
  fr: {
    vat: 20,
    name: 'France',
  },
  de: {
    vat: 19,
    name: 'Germany',
  },
  gr: {
    vat: 24,
    name: 'Greece',
  },
  hu: {
    vat: 27,
    name: 'Hungary',
  },
  ie: {
    vat: 23,
    name: 'Ireland',
  },
  is: {
    vat: 24,
    name: 'Iceland',
  },
  it: {
    vat: 22,
    name: 'Italy',
  },
  lv: {
    vat: 21,
    name: 'Latvia',
  },
  li: {
    vat: 7.70,
    name: 'Liechtenstein',
  },
  lt: {
    vat: 21,
    name: 'Lithuania',
  },
  lu: {
    vat: 16,
    name: 'Luxembourg',
  },
  mt: {
    vat: 18,
    name: 'Malta',
  },
  md: {
    vat: 20,
    name: 'Moldova',
  },
  nl: {
    vat: 21,
    name: 'Netherlands',
  },
  no: {
    vat: 25,
    name: 'Norway',
  },
  pl: {
    vat: 23,
    name: 'Poland',
  },
  pt: {
    vat: 23,
    name: 'Portugal',
  },
  ro: {
    vat: 19,
    name: 'Romania',
  },
  sk: {
    vat: 20,
    name: 'Slovak Republic',
  },
  si: {
    vat: 22,
    name: 'Slovenia',
  },
  es: {
    vat: 21,
    name: 'Spain',
  },
  se: {
    vat: 25,
    name: 'Sweden',
  },
  ch: {
    vat: 7.70,
    name: 'Switzerland',
  },
  gb: {
    vat: 20,
    name: 'United Kingdom',
  }
}