import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChatbotData } from '@web-solutions/chat-flow/src/types'

import type { ChatFlow } from 'src/screens/chat-flow/types';

export interface ChatState {
  chatbotData: ChatbotData | null;
  branchingFlow: ChatFlow[];
}

const initialState: ChatState = {
  chatbotData: null,
  branchingFlow: []
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatbotData: (state, action: PayloadAction<ChatbotData>) => {
      state.chatbotData = action.payload;
    },
    setBranchingFlow: (state, action: PayloadAction<ChatFlow[]>) => {
      state.branchingFlow = [...state.branchingFlow, ...action.payload];
    },
  }
})

export const blacklist = ['branchingFlow'];