import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useResolvedPath, useLocation } from "react-router-dom";
import _throttle from 'lodash/throttle';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { FaceCapture } from '@web-solutions/face-reading/types';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import { Preloader, Text } from '@web-solutions/core/ui-elements';

import { t, T } from '../localization';

import { LoadMethod } from '../constants';

import { getTKeyPartByContentMode } from '../utils/getContentMode';

import type { ContentMode } from '../types';

import { getStatus } from './detection';
import { Camera } from './camera';
import { CameraShape, DIMS } from './shape';

import classes from './style.module.scss';
import { Animations } from './components/animations';
import { RateBigAnimation } from './components/animations/rate-big';
import { HeartAnimation } from './components/animations/heart';
import { HeartRateLineAnimation } from './components/animations/heart-rate-line';
import { TimerFullAnimation } from './components/animations/timer-full';
import { handleFaceDetection } from './detection/faceapi';
import { faceSend } from './detection/mediapipe';

const tKey = 'capture';
interface FaceReadingCaptureProps {
  cameraInitTimeout: number,
  onSuccess: (data: any, method: LoadMethod) => void,
  onError: (err: any) => void,
  eventCategory: string,
  isBackAvailable: boolean,
  captureConfig?: FaceCapture;
  className?: string;
  contentMode?: ContentMode
}

const BASE_MEASURE_TIME = 15000

export const FaceReadingCapture: React.FC<FaceReadingCaptureProps> = ({
  className,
  cameraInitTimeout,
  onSuccess,
  onError,
  eventCategory,
  isBackAvailable,
  captureConfig,
  contentMode
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const match = useResolvedPath('');
  const r = useRef(false);
  const refId = useRef(0);
  const camera = useRef<{ camera?: Camera, ready?: boolean }>({});
  const [isFaceDetected, setIsFaceDetected] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [loading, setLoading] = useState(true);

  const intervalWorker: Worker | undefined = useMemo(
    () => {
      if (window.Worker) {
        const js = `
        var interval;
        self.onmessage = (e) => {
          if (e.data === 'start') {
            interval = setInterval(() => {
              self.postMessage('');
            }, 100)
          } else if (e.data === 'stop') {
            clearInterval(interval);
          }
        };
        `
        const blob = new Blob([js], { type: "application/javascript" })
        const objURL = URL.createObjectURL(blob)
        const worker = new Worker(objURL)
        worker.addEventListener("error", (e) => {
          URL.revokeObjectURL(objURL)
        })
        return worker;
      }
    },
    []
  );

  const stressScanEnabled = captureConfig?.stressScanEnabled;
  const isAutoTakeAfterScan = captureConfig?.isAutoTakeAfterScan;
  const withHeartRateLine = captureConfig?.withHeartRateLine;
  const duration = captureConfig?.duration || BASE_MEASURE_TIME;
  const mode = captureConfig?.mode;
  const faceLib = captureConfig?.faceLib;

  const [isFaceDetecting] = useState(!location?.state?.retake && stressScanEnabled && getStatus() === 'ready');

  const [timeLeft, setTimeLeft] = useState<number>(location?.state?.retake ? 0 : duration);

  const dims = isFaceDetecting && mode !== 'basic' ? { width: window.innerWidth, height: window.innerHeight } : DIMS;

  const setFaceDetected = _throttle((val) => {
    setIsFaceDetected((prev) => {
      if (prev !== val) {
        return val
      }
      return prev
    });
  }, 100);

  useEffect(() => {
    return () => {
      if (intervalWorker) {
        intervalWorker.terminate()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isFaceDetected) {
      Analytics.trackEvent('face_detection', 'timer_start')
      intervalWorker?.postMessage('start');
    } else if (!isDone) {
      intervalWorker?.postMessage('stop');
      setTimeLeft(location?.state?.retake ? 0 : duration)
    }
  }, [intervalWorker, isFaceDetected, isDone, location, duration]);

  useEffect(() => {
    if (intervalWorker) {
      intervalWorker.onmessage = (e: MessageEvent<string>) => {
        setTimeLeft((prev) => {
          if (prev === 0) {
            setIsDone(true);
            Analytics.trackEvent('face_detection', 'timer_end')
            return prev
          }
          return prev - 100
        })
      }
    }
  }, [intervalWorker])

  useEffect(() => {
    if (isFaceDetecting) {
      Analytics.trackEvent('face_detection', EVENT_ACTION.OPEN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    (async () => {
      async function render() {
        if (!r.current) {
          camera.current.camera?.drawCtx();
        }

        if (!camera.current.ready) {
          const canvas = document.createElement('canvas')
          const realCanvas = document.getElementById('canvas') as HTMLCanvasElement;

          if (canvas.toDataURL() === realCanvas?.toDataURL()) {
            camera.current.ready = false
            refId.current = requestAnimationFrame(render);
            return;
          } else {
            camera.current.ready = true
            setLoading(false);
            Analytics.trackEvent(eventCategory, 'camera_ready');
          }
        }

        if (isFaceDetecting && faceLib === 'mediapipe') {
          const isStaticMode = mode === 'staticFullMode'
          const detected = await faceSend(camera.current.camera, isStaticMode);
          setFaceDetected(detected);
        }

        if (isFaceDetecting && faceLib === 'faceapi') {
          const detected = await handleFaceDetection({ camera, dims, isStatic: mode === 'staticFullMode' });
          setFaceDetected(detected);
        }

        refId.current = requestAnimationFrame(render);
      }

      try {
        camera.current.camera = await Camera.setupCamera(cameraInitTimeout, dims);
        render();
      } catch (err) {
        onError(err);
      }
    })();

    return () => {
      cancelAnimationFrame(refId.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDone && isAutoTakeAfterScan) {
      cancelAnimationFrame(refId.current)
      handleCaptureClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone, isAutoTakeAfterScan])

  const handleCaptureClick = async () => {
    if (isFaceDetecting) {
      Analytics.trackEvent('face_detection', EVENT_ACTION.CLICK)
    }
    if (camera.current) {
      r.current = true;

      try {
        camera.current?.camera?.drawCtx();
        const img = camera.current?.camera?.canvas?.toDataURL('image/jpg');
        camera.current?.camera?.dispose();
        onSuccess({ img, ...dims, isFullScreen: isFaceDetecting && mode !== 'basic' }, LoadMethod.CAPTURE);
      } catch (ex) {
        console.warn(ex);
        onError(ex);
        r.current = false;
      }
    } else {
      onError(Error('camera-not-ready'));
    }
  };

  const handleBackClick = useCallback(() => {
    Analytics.trackEvent(eventCategory, 'click_back');
    navigate(`/${match.pathname.split('/')[1]}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  const speed = duration ? BASE_MEASURE_TIME / duration : 1;

  const isFullScreen = isFaceDetecting && mode !== 'basic'

  return (
    <div className={classes.root}>
      {loading && <Preloader />}
      <div className={classNames(classes.main, { [classes.full_screen]: isFullScreen })}>
        {mode === 'fullMode' && isFaceDetecting && <div className={classes.bg} />}
        {isFullScreen && timeLeft !== 0 &&
          <>
            <div className={classes.top_animations}>
              <RateBigAnimation isFaceDetected={isFaceDetected} />
              <HeartAnimation isFaceDetected={isFaceDetected} speed={speed} />
            </div>
            {!loading && <span className={classes.face_instruction}>
              {isFaceDetected ?
                <T k={`${tKey}${getTKeyPartByContentMode(contentMode)}.measuring`} tOptions={{ secs: duration ? duration / 1000 : BASE_MEASURE_TIME / 1000 }} components={{ highlight: <span className={classes.highlighted} /> }} />
                :
                <T k={`${tKey}.instruction_full`} components={{ highlight: <span className={classes.highlighted} /> }} />
              }
            </span>}
            <div className={classes.bottom_animations}>
              {withHeartRateLine && <HeartRateLineAnimation speed={speed} isFaceDetected={isFaceDetected} />}
              <TimerFullAnimation speed={speed} timeLeft={timeLeft} isFaceDetected={isFaceDetected} />
            </div>
          </>
        }
        {
          isBackAvailable && (
            <div className={classes.back} onClick={handleBackClick} />
          )
        }
        <canvas id='canvas' className={classes.canvas} />
        <video id='video' className={classes.video} playsInline />
        {!loading && <CameraShape className={(isFaceDetecting && mode === 'staticFullMode') ? classes.shape : ''} />}
      </div>
      {loading ?
        <Text className={classes.loading_text}>{t(`${tKey}.loading`)}</Text>
        :
        <div className={classNames(classes.footer, { [classes.full_screen]: isFullScreen })}>
          {(!isFaceDetecting || timeLeft === 0) && <>
            <span className={classes.instruction}>{t(`${tKey}.instruction`)}</span>
            <button className={classes.button_container} onClick={handleCaptureClick}>
              <span className={classes.button} />
            </button>
          </>}
          {(isFaceDetecting && timeLeft !== 0 && mode === 'basic') && <>
            <span className={classes.face_detection}>
              <T k={`${tKey}${getTKeyPartByContentMode(contentMode)}.measuring`} tOptions={{ secs: duration ? duration / 1000 : BASE_MEASURE_TIME / 1000 }} components={{ highlight: <span className={classes.highlighted} /> }} />
            </span>
            <Animations
              speed={speed}
              baseTime={duration}
              timeLeft={timeLeft}
              isFaceDetected={isFaceDetected}
            />
          </>
          }
        </div>}
    </div>
  );
};
