import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useState, useRef, useMemo } from 'react';

import { t } from '@web-solutions/module-localization';

import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { Subscription } from 'core/interfaces/billing';
import { PaymentFormOptions } from 'core/payment/types';
import { InjectedPaymentMethod } from 'core/constants/remote-config';
import { ProductDetails, selectInjectedPaymentMethod } from 'core/store/billing/selectors';

import { useFormProps } from '../../hooks/use-form-props';
import { ApplePayContainer } from '../applepay';
import { GooglePayContainer } from '../googlepay';
import { MercadoContainer } from '../mercado';
import { CardContainer } from '../card';
import { PayPalContainer } from '../paypal';

import classes from '../../style.module.scss';

export interface PaymentMethodsProps {
  isSimpleView: boolean;
  activeProduct: ProductDetails,
  isBaseModal: boolean;
  showCard?: boolean;
  showMercadoPago: boolean;
  upsaleTrigger?: string;
  options: PaymentFormOptions;
  showApplePay: boolean;
  showPayPal: boolean;
  showGooglePay: boolean;
  onSubmit: (formData: any) => Promise<void>;
  onSuccess: (purchase: Subscription) => void;
  onError: (error: any) => void;
  onApplePayClick: () => void;
  onApplePaySubmit: (formData: any) => Promise<void>;
  onGooglePayClick: () => void;
  onGooglePaySubmit: (formData: any) => Promise<void>;
  onPayPalSubmit: (formData: any) => Promise<void>;
  onPayPalSuccess: (p: Subscription) => void;
  onPayPalError: (e: any) => void;
  onUserInfoChange: (data: any) => void;
}

const tKey = 'core.payment_popup';

const defaultOrder = [InjectedPaymentMethod.APPLEPAY, InjectedPaymentMethod.GOOGLEPAY, InjectedPaymentMethod.PAYPAL, InjectedPaymentMethod.MERCADO, InjectedPaymentMethod.CARD];

export const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  isSimpleView,
  isBaseModal,
  activeProduct,
  showCard = true,
  showMercadoPago,
  upsaleTrigger,
  options,
  showApplePay,
  showPayPal,
  showGooglePay,
  onSubmit,
  onSuccess,
  onError,
  onApplePayClick,
  onApplePaySubmit,
  onGooglePayClick,
  onGooglePaySubmit,
  onPayPalSubmit,
  onPayPalSuccess,
  onPayPalError,
  onUserInfoChange,
}) => {
  const { injectedPaymentMethods, cardFormLayout, cardFormCollapsed } = useRemoteConfig();

  const paymentMethods = useMemo(() => {
    const flatted = injectedPaymentMethods.flat()

    defaultOrder.reverse().forEach((item) => {
      if (!flatted.includes(item)) {
        flatted.unshift(item);
      }
    })

    return flatted;

  }, [injectedPaymentMethods]);



  const prevCondition = useRef(false);

  const [showCardForm, setShowCardForm] = useState(!cardFormCollapsed || (!showApplePay && !showGooglePay && !showPayPal));

  const injectedPaymentMethod = useSelector(selectInjectedPaymentMethod);

  const injectedApplePay = injectedPaymentMethod?.includes(InjectedPaymentMethod.APPLEPAY);
  const injectedPayPal = injectedPaymentMethod?.includes(InjectedPaymentMethod.PAYPAL);
  const injectedGooglePay = injectedPaymentMethod?.includes(InjectedPaymentMethod.GOOGLEPAY);
  const injectedCard = !!injectedPaymentMethod?.includes(InjectedPaymentMethod.CARD);
  const injectedMercadoPago = injectedPaymentMethod?.includes(InjectedPaymentMethod.MERCADO);

  const handlePayWithCardClick = (e: MouseEvent) => {
    e.preventDefault();
    if (showApplePay || showPayPal || showGooglePay) {
      setShowCardForm(!showCardForm);
    }
  };

  const { cardFormProps, expandWrapStyle, formWrapStyle } = useFormProps({
    isSimpleView,
    activeProduct,
    cardFormLayout,
    options,
    showCardForm,
    injectedCard,
    onSubmit,
    onSuccess,
    onError,
    onApplePayClick,
    onApplePaySubmit,
    onGooglePayClick,
    onGooglePaySubmit,
    onUserInfoChange,
  })

  const RENDER_FUNCTIONS = {
    [InjectedPaymentMethod.APPLEPAY]: () => {
      const isButtonVisible = showApplePay && (injectedApplePay || isBaseModal)
      return {
        condition: isButtonVisible,
        component: <ApplePayContainer
          activeProduct={activeProduct}
          isButtonVisible={isButtonVisible}
          onApplePayClick={onApplePayClick}
          onApplePaySubmit={onApplePaySubmit}
          options={options}
        />,
      }
    },
    [InjectedPaymentMethod.GOOGLEPAY]: () => {
      const isButtonVisible = showGooglePay && (injectedGooglePay || isBaseModal)
      return {
        condition: isButtonVisible,
        component: <GooglePayContainer
          isButtonVisible={isButtonVisible}
          options={options}
        />
      }
    },
    [InjectedPaymentMethod.PAYPAL]: () => {
      const isButtonVisible = showPayPal && (injectedPayPal || isBaseModal);
      return {
        condition: isButtonVisible,
        component: <PayPalContainer
          isButtonVisible={isButtonVisible}
          activeProduct={activeProduct}
          onPayPalSubmit={onPayPalSubmit}
          onPayPalSuccess={onPayPalSuccess}
          onPayPalError={onPayPalError}
        />
      }
    },
    [InjectedPaymentMethod.CARD]: () => {
      const isVisible = showCard && (injectedCard || isBaseModal);
      return {
        condition: isVisible,
        component: <CardContainer
          isVisible={isVisible}
          showCardForm={showCardForm}
          showApplePay={showApplePay}
          showGooglePay={showGooglePay}
          showPayPal={showPayPal}
          injectedCard={injectedCard}
          isSimpleView={isSimpleView}
          formWrapStyle={formWrapStyle}
          expandWrapStyle={expandWrapStyle}
          cardFormProps={cardFormProps}
          handlePayWithCardClick={handlePayWithCardClick}
        />
      }
    },
    [InjectedPaymentMethod.MERCADO]: () => {
      const isButtonVisible = showMercadoPago && (injectedMercadoPago || isBaseModal);
      return {
        condition: isButtonVisible,
        component: <MercadoContainer
          isButtonVisible={isButtonVisible}
          activeProduct={activeProduct}
          upsaleTrigger={upsaleTrigger}
        />
      }
    }
  }

  return <div className={classNames(classes.payment, classes.column)}>
    <div className={classes.paymentMethods}>
      {paymentMethods.map((method, index) => {
        const { component, condition } = RENDER_FUNCTIONS[method]?.() || {};
        const isShowOr = prevCondition.current && condition;

        if (condition) {
          prevCondition.current = condition
        }

        if (index === paymentMethods.length - 1) {
          prevCondition.current = false;
        }

        return <>
          {isShowOr && (
            <div className={classes.or}>
              <p>{t(`${tKey}.or`)}</p>
            </div>
          )}
          {component}
        </>
      })}
    </div>
  </div>
}