import { useState, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import _map from 'lodash/map';
import _range from 'lodash/range';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import Analytics from '@web-solutions/module-analytics';

import { t } from '../../../../localization';
import { DATE_YEARS, DATE_MONTH } from '../../../constans/dropdown';
import { Button } from '../../button';
import { Select } from '../../select';
import { BaseAnswer } from '../../base-answer';
import type { AnswerProcessorParams, BaseAnswerProps, Answer } from '../../../types';
import { UserAnswer } from '../../user-answer';
import { useBaseAnswer } from '../../../hooks/use-base-answer';

import classes from './style.module.scss';

const tKey = 'chat_flow.date_answer';

interface DateAnswerProps extends BaseAnswerProps {
  data: {
    answer: Answer[];
    skipText?: string;
  };
  onSuccess: (data: AnswerProcessorParams, screen: string) => void;
}

export const DateAnswer: React.FC<DateAnswerProps> = ({
  messages,
  postMessages,
  analyticsCategory,
  withBoost,
  isActive,
  onNextMessage,
  onSuccess
}) => {
  const { display, handleNextMessage } = useBaseAnswer({ analyticsCategory });

  const [date, setDate] = useState<string | null>(null);
  const [valid, setValid] = useState(false);
  const [daysAmount, setDaysAmount] = useState(31);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleNextMessage();
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    const data: { [key in string]: string } = {};
    formData.forEach((value, key) => data[key] = value as string);

    const res = `${data.year ?? 2000}-${data.month ?? 1}-${data?.day ?? 1}`;

    setDate(res);
    onNextMessage && onNextMessage();
    if (!postMessages?.length) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: res });
      onSuccess({ value: res }, analyticsCategory);
    }
  }

  const handleFinish = useCallback((finished?: boolean) => {
    handleNextMessage();
    if (finished) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: date });
      onSuccess({ value: date! }, analyticsCategory);
    }
  }, [handleNextMessage, onSuccess, date, analyticsCategory])


  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    const data: { [key in string]: string } = {};
    formData.forEach((value, key) => data[key] = value as string);

    const res = `${data.year ?? 2000}-${data.month ?? 1}-${1}`;
    const daysInMonth = dayjs(res).daysInMonth()

    setDaysAmount(daysInMonth)
    if (dayjs(res).isValid() && Object.keys(data).length === 3 && +data.day <= daysInMonth) {
      setValid(true);
    }
  }

  const dates = useMemo(() => _map(_range(daysAmount), i => ({ value: dayjs(`2020-01-${i + 1}`).format('D') })), [daysAmount]);

  const handleNewMessage = () => {
    handleFinish();
    onNextMessage && onNextMessage()
  }

  return <BaseAnswer
    analyticsCategory={analyticsCategory}
    messages={messages}
    postMessages={postMessages}
    display={display}
    isActive={isActive}
    withBoost={withBoost}
    onNextMessage={handleNewMessage}
  >
    {display >= messages.length &&
      <>
        {date ?
          <UserAnswer>
            {dayjs(date).format('DD.MM.YYYY')}
          </UserAnswer>
          :
          <form onSubmit={handleSubmit} onChange={handleChange} className={classes.wrapper}>
            <Select name="year" placeholder={t(`${tKey}.year`)} options={DATE_YEARS} className={classes.select} />
            <div className={classes.divider} />
            <Select name="month" placeholder={t(`${tKey}.month`)} options={DATE_MONTH} className={classes.select} />
            <div className={classes.divider} />
            <Select name="day" placeholder={t(`${tKey}.day`)} options={dates} className={classes.select} />
            <Button disabled={!valid} />
          </form>
        }
      </>
    }
  </BaseAnswer>
}