import _map from 'lodash/map';
import _chunk from 'lodash/chunk';
import _orderBy from 'lodash/orderBy'
import React, { useEffect, useRef, useMemo, useCallback, forwardRef, } from 'react';
import { useSelector } from 'react-redux';
import * as Facemesh from '@mediapipe/face_mesh';
import { LandmarkConnectionArray } from '@mediapipe/drawing_utils';
//@ts-ignore
import { drawConnectors } from '@mediapipe/drawing_utils/drawing_utils';
import classNames from 'classnames';

import { selectFaceImg, selectFaceImgDims, selectFaceKeyPoints } from '../../../store/selectors';
import { ProgressDescriptionType } from '../../../../types';

import classes from './style.module.scss';

const MESHES: Array<[LandmarkConnectionArray, string]> = [
  [Facemesh.FACEMESH_FACE_OVAL, '#FFFF00'],
  [Facemesh.FACEMESH_RIGHT_EYE, '#00FFFF'],
  [Facemesh.FACEMESH_LEFT_EYE, '#00FFFF'],
  // [Facemesh.FACEMESH_RIGHT_IRIS, '#00FFFF'],
  // [Facemesh.FACEMESH_LEFT_IRIS, '#00FFFF'],
  [Facemesh.FACEMESH_RIGHT_EYEBROW, '#00FF00'],
  [Facemesh.FACEMESH_LEFT_EYEBROW, '#00FF00'],
  [Facemesh.FACEMESH_LIPS, '#FF0000'],
]

interface FaceReadingPreviewProps {
  animated?: boolean;
  className?: string;
  widthPercent?: number;
  progressDescriptionType?: ProgressDescriptionType;
}

export const FaceReadingPreview = forwardRef<HTMLDivElement, FaceReadingPreviewProps>(({
  animated,
  className,
  widthPercent,
  progressDescriptionType,
}, ref) => {
  const img = useSelector(selectFaceImg);
  const dims = useSelector(selectFaceImgDims);
  const points = useSelector(selectFaceKeyPoints);

  const scale = useMemo(() => {
    if (progressDescriptionType === ProgressDescriptionType.COLUMNS && dims?.isFullScreen) {
      return Math.min(window.innerWidth, 375) * (widthPercent || 1) / dims?.width * 0.75;
    }

    return window.innerWidth * (widthPercent || 1) / dims?.width;
  }, [widthPercent, dims, progressDescriptionType]);

  const refCanvas = useRef<HTMLCanvasElement & { lines: any, drawIndex: number, canvasCtx: any, drawTimer: NodeJS.Timeout, }>(null);

  const landmarks = useMemo(() => _map(points, p => ({
    x: p.x / dims?.width,
    y: p.y / dims?.height,
    z: 0,
  })), [points, dims]);

  const drawLine = useCallback(() => {
    const l = refCanvas.current?.lines?.[refCanvas.current.drawIndex];
    if (l) {
      drawConnectors(refCanvas.current.canvasCtx, landmarks, l.connectors, { color: l.color, lineWidth: 1, });
      ++refCanvas.current.drawIndex;
      if (refCanvas.current.drawIndex < refCanvas.current.lines.length) {
        refCanvas.current.drawTimer = setTimeout(drawLine, l.timeout);
      }
    }
  }, [landmarks]);

  useEffect(() => {
    if (refCanvas.current) {
      clearTimeout(refCanvas.current.drawTimer);
      refCanvas.current.canvasCtx = refCanvas.current?.getContext('2d');

      refCanvas.current.canvasCtx.clearRect(0, 0, dims?.width, dims?.height);

      if (animated) {
        const lines = _orderBy(Facemesh.FACEMESH_TESSELATION, i => landmarks?.[i[0]]?.y);

        refCanvas.current.lines = _map(_chunk(lines, 5), chunk => ({
          connectors: chunk,
          color: '#FFFFFF',
          timeout: 1
        }));

        MESHES.forEach(([mesh, color]) => {
          refCanvas.current!.lines = refCanvas.current!.lines.concat(_map(_chunk(mesh, 1), chunk => ({
            connectors: chunk,
            color,
            timeout: 20,
          })));
        })

        refCanvas.current.drawIndex = 0;
        refCanvas.current.drawTimer = setTimeout(drawLine, 1);
      } else {
        drawConnectors(refCanvas.current.canvasCtx, landmarks, Facemesh.FACEMESH_TESSELATION, { color: '#FFFFFF', lineWidth: 1 });
        MESHES.forEach(([mesh, color]) => {
          drawConnectors(refCanvas.current!.canvasCtx, landmarks, mesh, { color, lineWidth: 1 });
        })
      }
    }
  }, [dims, landmarks, refCanvas, animated, drawLine]);

  return (
    <div ref={ref} className={classNames(classes.container, className)} style={{ scale: String(scale) }}>
      <img src={img} alt="face" width={dims?.width} height={dims?.height} />
      <canvas ref={refCanvas} width={dims?.width} height={dims?.height}></canvas>
    </div>
  );
});
