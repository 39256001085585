import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { initializeApp as initFirebase } from 'firebase/app';

import Analytics from '@web-solutions/module-analytics';
import Attribution from '@web-solutions/module-attribution';

import { ThemeContextProvider } from 'core/hooks/use-theme-context';
import { Theme } from 'core/constants/theme';

import { APP_LINKS } from 'src/constants/general';
import { load } from 'src/store/app/actions';
import { getMagnusToken } from 'src/store/app/utils';

import packageInfo from '../package.json';

import configureStore from './store';
import { initLocalization } from './localization';

import 'react-toastify/dist/ReactToastify.min.css';
import 'src/styles/global.scss';
import 'src/styles/index.scss';
import 'src/styles/variables.css';

const { store, persistor } = configureStore();

const App = (AppRouter: React.FC) => {

  return (
    <StrictMode>
      <Provider store={store}>
        <ThemeContextProvider value={{ theme: Theme.dark }}>
          <PersistGate loading={null} persistor={persistor}>
            <AppRouter />
            <ToastContainer position='bottom-center' autoClose={5000} closeOnClick />
          </PersistGate>
        </ThemeContextProvider>
      </Provider>
    </StrictMode>
  );
};


initFirebase({
  apiKey: 'AIzaSyBIEXn1GMKpONHDUrxfhz6SHeRLCrhSCpc',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: 'astroline-web-2-0',
  storageBucket: 'astroline-web-2-0.appspot.com',
  messagingSenderId: '613807524683',
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const init = async () => {
  initLocalization();
  Analytics.init(getMagnusToken(), packageInfo.version, { skipSessionIncrement: window.location.pathname.split('/')[1] === 'asb', evtruckStorageKey: 'quiz' });
  Attribution.init(APP_LINKS);

  return store.dispatch(load());
}

export const render = (AppRouter: React.FC) => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  init().then(() => {
    root.render(App(AppRouter));
  })
};
