import { useEffect } from 'react';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { BaseAnswer } from '../base-answer';
import type { AnswerProcessorParams, BaseAnswerProps } from '../../types';
import { useBaseAnswer } from '../../hooks/use-base-answer';

interface TransitionAnswerProps extends BaseAnswerProps {
  onSuccess: (id: AnswerProcessorParams, screen: string) => void;
}

export const TransitionAnswer: React.FC<TransitionAnswerProps> = ({
  messages,
  postMessages,
  analyticsCategory,
  withBoost,
  isActive,
  onNextMessage,
  onSuccess,
}) => {

  const { display, handleNextMessage } = useBaseAnswer({ analyticsCategory });

  useEffect(() => {
    if (display >= messages.length) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS);
      onSuccess({ value: '' }, analyticsCategory)
    }
  }, [analyticsCategory, display, messages.length, onSuccess])

  const handleNewMessage = () => {
    handleNextMessage();
    onNextMessage && onNextMessage()
  }

  return <BaseAnswer
    isActive={isActive}
    analyticsCategory={analyticsCategory}
    messages={messages}
    postMessages={postMessages}
    display={display}
    onNextMessage={handleNewMessage}
    withBoost={withBoost}
  />
}