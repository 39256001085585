import React from 'react';
import classNames from 'classnames';

import { useWindowEnd } from '@web-solutions/core/hooks/use-window-end';

import { Button, LinkButton } from '@web-solutions/core/ui-elements';

import { useManageContext } from '../../hooks/use-manage-context';

import classes from './style.module.scss';

interface Props {
  isFixedButton: boolean,
  isFixedLink?: boolean,
  buttonTitle: string,
  linkTitle: string,
  onButtonClick: () => void,
  onLinkClick: () => void;
}

const FixedButtonsInWrapper: React.FC<Props> = ({ isFixedButton, isFixedLink, buttonTitle, linkTitle, onButtonClick, onLinkClick }) => {
  const { showAltCancelButton } = useManageContext();

  const { isEnd } = useWindowEnd();

  return (
    <>
      <div className={classNames(classes.button_wrap, {
        [classes.hidden_button_wrap_fix_link]: isEnd && isFixedLink,
        [classes.static_buttons]: !isFixedButton,
        [classes.fix_button]: isFixedButton,
        [classes.hidden_button_wrap]: isEnd && !isFixedLink,
        [classes.fix_link]: isFixedButton && isFixedLink,
      })}>
        <Button
          onClick={onButtonClick}
          title={buttonTitle}
          className={classes.main_button}
        />
        {showAltCancelButton
          ? <Button
            onClick={onLinkClick}
            title={linkTitle}
            titleClassName={classes.alt_link_button_title}
            className={classNames(classes.bottom_button, classes.alt_link_button)}
          /> :
          <LinkButton
            onClick={onLinkClick}
            className={classes.bottom_button}
          >
            {linkTitle}
          </LinkButton>}
      </div>
      {isFixedButton && <div className={classes.gap} />}
    </>
  )
}


export default FixedButtonsInWrapper;