import { useState, useCallback } from 'react';
import dayjs from "dayjs";

import { convertArrToLocaleTime } from '@web-solutions/core/utils/date';
import { LinkButton } from '@web-solutions/core/ui-elements';
import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { TIME_MINUTES, TIME_HOURS, TIME } from '../../../constans/dropdown';
import { Button } from '../../button';
import { Select } from '../../select';
import { BaseAnswer } from '../../base-answer';
import type { AnswerProcessorParams, BaseAnswerProps, Answer } from '../../../types';
import { UserAnswer } from '../../user-answer';
import { useBaseAnswer } from '../../../hooks/use-base-answer';

import classes from './style.module.scss';

interface TimeAnswerProps extends BaseAnswerProps {
  data: {
    answer: Answer[];
    skipText?: string;
  };
  onSuccess: (data: AnswerProcessorParams, screen: string) => void;
}

export const TimeAnswer: React.FC<TimeAnswerProps> = ({
  messages,
  postMessages,
  data,
  withBoost,
  analyticsCategory,
  isActive,
  onNextMessage,
  onSuccess
}) => {

  const { skipText } = data;

  const { display, handleNextMessage } = useBaseAnswer({ analyticsCategory });

  const [time, setTime] = useState<string | null>(null);
  const [skiped, setSkiped] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    const data: string[] = [];
    formData.forEach((value) => data.push(value as string));
    const time = convertArrToLocaleTime(data);
    const res = dayjs(time, 'LT').format('HH:mm');
    handleNextMessage();
    setTime(time);
    onNextMessage && onNextMessage();
    if (!postMessages?.length) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: time });
      onSuccess({ value: res }, analyticsCategory);
    }
  }


  const handleFinish = useCallback((finished?: boolean) => {
    handleNextMessage();
    if (finished) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: time });
      onSuccess({ value: time! }, analyticsCategory);
    }
  }, [handleNextMessage, onSuccess, time, analyticsCategory])


  const handleSkip = () => {
    if (!postMessages?.length) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SKIP);
      onSuccess({ value: '' }, analyticsCategory);
    }
    setSkiped(true);
    handleNextMessage();
  };

  const handleNewMessage = () => {
    handleFinish();
    onNextMessage && onNextMessage()
  }

  return <BaseAnswer
    analyticsCategory={analyticsCategory}
    messages={messages}
    postMessages={postMessages}
    display={display}
    isActive={isActive}
    withBoost={withBoost}
    onNextMessage={handleNewMessage}
  >
    {display >= messages.length &&
      <>
        {skiped ?
          <UserAnswer>
            {skipText}
          </UserAnswer>
          :
          <>
            {time ?
              <UserAnswer>
                {time}
              </UserAnswer>
              :
              <>
                <form onSubmit={handleSubmit} className={classes.wrapper}>
                  <Select name="hours" options={TIME_HOURS} className={classes.select} />
                  <div className={classes.divider} />
                  <Select name="minutes" options={TIME_MINUTES} className={classes.select} />
                  <div className={classes.divider} />
                  <Select name="time" options={TIME} className={classes.select} />
                  <Button />
                </form>
                {skipText && <LinkButton className={classes.skip} onClick={handleSkip}>{skipText}</LinkButton>}
              </>
            }
          </>
        }
      </>
    }
  </BaseAnswer>
}