import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
  paymentProject?: string;
  loaded: boolean;
  pending: boolean;
}

const initialState: AppState = {
  paymentProject: process.env.REACT_APP_PAYMENT_PROJECT,
  loaded: false,
  pending: false,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setPaymentProject: (state, action: PayloadAction<string>) => {
      state.paymentProject = action.payload;
    }
  }
})

export const blacklist = ['loaded', 'pending'];

export { slice as appSlice };
