import type { FC } from 'react'

import { Text } from '@web-solutions/core/ui-elements';

import IMAGES from '../images';
import { t } from '../../../localization';

import type { ChatbotData } from '../../types';

import classes from './style.module.scss';

const tKey = 'chat_flow.header';

interface HeaderProps {
  chatbotData: ChatbotData
}

export const Header: FC<HeaderProps> = ({ chatbotData }) => {
  const { name, avatarLink } = chatbotData || {}

  return <div className={classes.header}>
    <div className={classes.content}>
      <img className={classes.img} src={avatarLink ?? IMAGES.AVA.src} srcSet={avatarLink ?? IMAGES.AVA.srcSet} alt='ava' />
      <div className={classes.text_wrap}>
        <Text className={classes.title}>
          {name ?? t(`${tKey}.name`)}
        </Text>
        <Text className={classes.text}>
          {t(`${tKey}.status`)}
        </Text>
      </div>
    </div>
  </div>
}