import * as faceapi from 'face-api.js';

import { IMAGES } from '../../welcome/images';

import { checkIfBoxInStaticOval } from '.';

let status: null | 'ready' | 'error' = null;
const options = new faceapi.TinyFaceDetectorOptions({ scoreThreshold: 0.4, inputSize: 128 });

export function getStatus() {
  return status;
}

export const loadModels = async () => {
  const MODEL_URL = (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PUBLIC_URL || '/' : '/') + 'models';

  return Promise.all([
    faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
  ])
    .then(() => {
      status = 'ready'
    })
    .catch((e) => {
      console.warn(e)
      status = 'error';
    })
}

let prev: any = null

let count = 0

export const handleFaceDetection = async (
  { camera, isStatic, dims }:
    { camera: any, isStatic: boolean, dims: { width: number, height: number } }
): Promise<boolean> => {
  if (camera.current && camera.current?.camera?.video) {
    const displaySize = {
      width: dims.width,
      height: dims.height
    }
    const detection = await faceapi.detectSingleFace(camera.current?.camera?.video!, options);
    const resizedDetections = faceapi.resizeResults({ detection }, displaySize);
    const shape = document.getElementById('face-shape')

    if (shape) {
      if (!!resizedDetections?.detection) {
        count = 0
        let b = prev;
        if (!b
          || Math.abs(b?.detection?.box?.left - resizedDetections?.detection?.box?.left) > 6
          || Math.abs(b?.detection?.box?.top - resizedDetections?.detection?.box?.top) > 10
        ) {
          b = prev = resizedDetections;
        }

        const l = b?.detection?.box?.left!
        const t = b?.detection?.box?.top!
        const w = b?.detection?.box?.width!
        const h = b?.detection?.box?.height!

        if (isStatic) {
          return checkIfBoxInStaticOval({ shape, l, t, w, h })
        }

        if (!isStatic) {
          //@ts-ignore
          shape.style = `left: initial; width: ${w}px; height: ${h}px; right: ${l}px; top: ${t}px; transform: translateY(-15%)`;
        }
      } else {
        count += 1
        if (count >= 7) {
          prev = undefined;
          //@ts-ignore
          shape.style = ''
          return false
        }
        return true
      }
    }
    return !!detection;
  }
  return false;
}

export function preheat() {
  const img = new Image();
  img.src = (new URL(IMAGES.FACE.src)).pathname;
  img.addEventListener('load', async function () {
    try {
      console.time('faceapi preheat')
      await faceapi.detectSingleFace(img, options);
      console.timeEnd('faceapi preheat')
    } catch (ex) {
      console.warn('faceapi preheat error', ex)
    }
  })
}