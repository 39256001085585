import { useState } from 'react';
import classNames from 'classnames';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { UserAnswer } from '../user-answer';

import classes from './style.module.scss';

interface AnswerButtonProps {
  onClick: (id: string) => void;
  id: string;
  text: string;
  withicon?: boolean;
}

export const AnswerButton: React.FC<AnswerButtonProps> = ({ onClick, id, text, withicon }) => {
  const [clicked, setClicked] = useState(false);

  const { chatFlow } = useRemoteConfig();

  const handleClick = () => {
    onClick(id);
    setClicked(true);
  }

  return <>
    {clicked ?
      <UserAnswer>
        {text}
      </UserAnswer>
      :
      <div className={classNames(classes.wrapper, { [classes.with_border]: chatFlow.answersStyle === 'with_border' })}>
        {withicon && <div className={classes.indicator} />}
        <button onClick={handleClick}>{text}</button>
      </div>
    }
  </>
}