import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { Input } from 'core/ui-elements';

import Autocomplete from './autocomplete';

import classes from './style.module.scss';

interface EmailFormProps {
  value: string,
  isValid?: boolean,
  onChange: (value: string) => {} | void,
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  inputStyleClassName?: string,
  helperItemClassName?: string,
  placeholder: string;
}

const EmailForm: React.FC<EmailFormProps> = ({
  value,
  isValid,
  onChange,
  onSubmit,
  inputStyleClassName,
  helperItemClassName,
  placeholder
}) => {
  const refInput = useRef(null);
  const [isShowHelper, setShowHelper] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { value } = e.target;

    onChange(value);

    if (value.length > 2) {
      setShowHelper(true);
    } else {
      setShowHelper(false);
    }
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(e);
  };

  const handleInputBlur = () => {
    setTimeout(() => setShowHelper(false), 100);
  };

  const inputStyle = classNames(
    classes.input,
    {
      [classes.input__invalid]: !isValid && !!value,
    },
    inputStyleClassName,
  );

  return (
    <form action="#" onSubmit={handleSubmitForm} className={classes.form}>
      <div className={classes.wrapper}>
        <Input
          //@ts-ignore
          type="email"
          ref={refInput}
          value={value}
          onChange={handleInputChange}
          className={inputStyle}
          placeholder={placeholder}
          name="email"
          id="email"
          onBlur={handleInputBlur}
        />
        {isShowHelper && (
          <Autocomplete
            value={value}
            onSelect={onChange}
            optionClassName={helperItemClassName}
          />
        )}
      </div>
    </form>
  );
};

export default EmailForm;
