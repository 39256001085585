import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import _pick from 'lodash/pick';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { searchAddress } from 'core/api/nominatim';

import Autocomplete from '../input-autocomplete';

const PlaceAutocomplete = ({
  value,
  onSelect,
  show
}) => {
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState(value?.label);

  useEffect(() => {
    setOptions(null);
    if (value?.label !== selected) {
      setSelected(false);
    }
    if (value?.label) {
      searchAddress(value?.label)
        .then(r => {
          setOptions(_map(_uniqBy(r, 'label'), i => _pick(i, 'label', 'lat', 'lon', 'address')));
        })
        .catch((err) => {
          if (err?.name === 'AbortError') {
            console.warn(err);
          } else {
            throw err;
          }
        });
    }
  }, [value, selected]);

  const handleSelect = useCallback((v) => {
    setSelected(v?.label);
    onSelect(v);
  }, [setSelected, onSelect]);

  return options && !selected && show
    ? (
      <Autocomplete
        options={options}
        onSelect={handleSelect}
      />
    )
    : null;
};

PlaceAutocomplete.propTypes = {
  value: PropTypes.shape({ label: PropTypes.string, lat: PropTypes.string, lon: PropTypes.string }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PlaceAutocomplete;
