import { useRemoteConfig } from "./use-remote-config"

export const useTheme = () => {
  const { theme } = useRemoteConfig();

  return {
    isDarkTheme: theme?.mode === 'dark',
    isLightTheme: theme?.mode === 'light',
    theme: theme?.mode || 'default',
  }
}