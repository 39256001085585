import { useState, } from 'react';
import queryString from 'query-string';

import Analytics from "@web-solutions/module-analytics";
import Attribution from '@web-solutions/module-attribution';

export const useReturnToApp = ({ category }: { category: string }) => {
  const [link] = useState(Attribution.getLinkToApp());

  const p = queryString.parse(window.location.search);
  const idfm = p.idfm;

  const handleReturnAppClick = () => {
    Analytics.trackEvent(category, 'return_to_app');
    window.location.href = link;
  };

  return {
    available: !!link && !!idfm,
    link,
    handleReturnAppClick
  }
}