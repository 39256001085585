import type { LString } from '@web-solutions/core/constants/remote-config';

import { SummarySection } from 'src/constants/remote-config';

import type { QuizNameKeys } from 'src/constants/screens';

export interface UpsalesBasket {
  title: LString,
  paymentInfo: {
    title: LString,
    withGift: boolean,
  },
  actionButtons: {
    submitButtonText: LString,
    skipButtonText: LString,
    withPrices: boolean,
    withSkipButton: boolean,
  }
}

export enum CompatibilityOfferMode {
  DEFAULT = 'default',
  LANDING = 'landing',
  CLOSED_BOOK = 'closed_book',
  FEATURES = 'features',
  ROUND_FEATURES = 'round_features',
  OPENED_BOOK = 'opened_book',
}
export interface CompatibilityOffer {
  mode: CompatibilityOfferMode,
  landing: {
    title: LString,
    activePriceText: LString,
    submitButtonText: LString,
    skipButtonText: LString,
    fixedPriceVisible: boolean,
    fixedSubmitButtonText: LString,
    fixedSkipButtonText: LString,
    withSkipButton: boolean,
  }
}

export interface EmailPage {
  viewMode: 'default' | 'fullscreen',
  title: LString,
  blurComponents?: QuizNameKeys[] | SummarySection[]
}

export interface AstrologerMinutesPage {
  buttonText: LString
}

export interface GenderPageWithProtectBadges {
  title?: LString,
  isProtectBadgesVisible?: boolean
}

export interface WelcomePageWithImage {
  title?: LString,
  subtitle?: LString,
  buttonTitle?: LString
}

export interface AstrologerPromoBlock {
  isVisible: boolean,
  minutes: number
}

export interface WelcomePopularQuestions {
  title?: LString,
  buttonTitle?: LString
}

export interface ReportLoader {
  mode: 'default' | 'taro'
}

export interface CompatibilityShipping {
  availableShippingCountries: string[],
  details: {
    buttonTitle: LString
  }
}

export interface SelectPlanByTrial {
  card: {
    isTitleShown: boolean,
    price?: LString,
  },
  title?: LString,
  subtitle?: LString
  buttonTitle?: LString,
}