import type { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

import { QuestionMessage } from '../message';
import type { BaseAnswerProps } from '../../types';

import classes from './style.module.scss';

interface AnswerProps extends PropsWithChildren<BaseAnswerProps> {
  display: number
}

export const BaseAnswer: FC<AnswerProps> = ({
  messages,
  postMessages,
  children,
  display,
  analyticsCategory,
  withBoost,
  isActive,
  onNextMessage,
}) => {
  const handleSuccessMessage = (finished?: boolean) => {
    onNextMessage(finished);
  };

  return <div className={classNames(classes.wrapper, { [classes.active]: isActive })}>
    {messages.map(({ node, text, type }, indx) => {
      if (display < indx) {
        return null
      }
      return <QuestionMessage
        analyticsCategory={analyticsCategory}
        type={type}
        key={text}
        node={node}
        withAva={indx === display || indx === display - 1 || (indx === messages.length - 1 && display > messages.length)}
        withIcon={indx === display - 1 || (indx === messages.length - 1 && display > messages.length)}
        text={text}
        onSuccess={handleSuccessMessage}
        withBoost={withBoost}
      />
    })}
    <div className={classes.childrenWrapper}>
      {children}
    </div>
    {!!postMessages?.length && postMessages?.map(({ text, type }, indx) => {
      const number = indx + 1;
      if (display < number + messages.length) {
        return null
      }

      return <QuestionMessage
        analyticsCategory={analyticsCategory}
        type={type}
        key={text}
        withAva={number + messages.length + 1 === display || number + messages.length === display}
        withIcon={number + messages.length + 1 === display}
        text={text}
        isLast={indx === postMessages?.length - 1}
        onSuccess={handleSuccessMessage}
        withBoost={withBoost}
      />
    })}
  </div>
}