import { useState, useCallback } from 'react';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { useBaseAnswer } from "../../../hooks/use-base-answer"
import type { Answer, AnswerProcessorParams, BaseAnswerProps } from "../../../types";
import { BaseAnswer } from "../../base-answer"
import { UserAnswer } from "../../user-answer";
import { Button } from '../../button';

import classes from './style.module.scss';

interface InputProps extends BaseAnswerProps {
  data: {
    answer: Answer[];
    skipText?: string;
  };
  onSuccess: (id: AnswerProcessorParams, screen: string) => void;
}

export const TextAnswer: React.FC<InputProps> = ({
  messages,
  postMessages,
  analyticsCategory,
  withBoost,
  isActive,
  onNextMessage,
  onSuccess,
}) => {

  const [value, setValue] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const { display, handleNextMessage } = useBaseAnswer({ analyticsCategory });

  const handleSubmit = () => {
    if (!postMessages?.length) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: value });;
      onSuccess({ value }, analyticsCategory);
    }
    setSubmitted(true);
    handleNextMessage();
    onNextMessage && onNextMessage();
  }

  const handleFinish = useCallback((finished?: boolean) => {
    handleNextMessage();
    if (finished) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: value });
      onSuccess({ value }, analyticsCategory);
    }
  }, [handleNextMessage, onSuccess, value, analyticsCategory])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 25) {
      setValue(e.target.value)
    }
  }

  const handleNewMessage = () => {
    handleFinish();
    onNextMessage && onNextMessage()
  }

  return <BaseAnswer
    analyticsCategory={analyticsCategory}
    messages={messages}
    postMessages={postMessages}
    display={display}
    isActive={isActive}
    withBoost={withBoost}
    onNextMessage={handleNewMessage}
  >
    {display >= messages.length &&
      <>
        {submitted ?
          <UserAnswer>
            {value}
          </UserAnswer>
          :
          <>
            <form onSubmit={handleSubmit} className={classes.wrapper}>
              <input
                placeholder={t(`chat_flow.${analyticsCategory}.placeholder`)}
                value={value}
                onChange={handleChange}
              />
              <Button disabled={!value} onClick={handleSubmit} />
            </form>
          </>
        }
      </>
    }
  </BaseAnswer >
}