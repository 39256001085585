import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import classNames from 'classnames';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { MessageLoader } from '../message-loader';
import { calculateDuration } from '../../utils/calculate-duration';
import IMAGES from '../images';

import classes from './style.module.scss';

interface Props {
  text: string;
  analyticsCategory: string;
  onSuccess: (isLast?: boolean) => void;
  withIcon?: boolean;
  type: string;
  node?: React.ReactNode;
  src?: string;
  withAva?: boolean;
  isLast?: boolean;
  withBoost?: boolean;
}

export const QuestionMessage: React.FC<Props> = ({ withBoost, analyticsCategory, node, text, onSuccess, withIcon, type, src, isLast, withAva }) => {
  const [animationEnded, setAnimationEnded] = useState(false);

  const { chatFlow } = useRemoteConfig();

  const chatbotData = useSelector((state: any) => state.chat.chatbotData)

  useEffect(() => {
    const symbolsPerSecond = withBoost ? chatFlow?.symbolsPerSecond * 2 : chatFlow?.symbolsPerSecond;
    const animationDuration = type === 'voice' ? 4500 : calculateDuration(text, symbolsPerSecond);
    const timeout = setTimeout(() => {
      setAnimationEnded(true)
      onSuccess(isLast);
    }, animationDuration);

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, type, isLast, chatFlow?.symbolsPerSecond])


  const MESSAGE: { [k in string]: JSX.Element } = {
    node: <>{node}</>,
    text: <>{text}</>
  }

  return <div className={classNames(classes.container, { [classes.with_icon]: withIcon })}>
    {animationEnded ?
      <div className={classNames(classes.wrapper, { [classes.node]: type === 'node' })}>
        {MESSAGE[type]}
      </div>
      :
      <MessageLoader />
    }
    {withAva && <img className={classes.img} src={chatbotData?.avatarLink ?? IMAGES.AVA.src} srcSet={chatbotData?.avatarLink ?? IMAGES.AVA.srcSet} alt="ava" />}
  </div>
}