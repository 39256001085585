import React, { useCallback, useState, useEffect, } from 'react';
import { useNavigate, useResolvedPath, Routes, Route } from "react-router-dom";
import { useDispatch } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { ProgressDescriptionType, FaceCapture } from '@web-solutions/face-reading/types';

import { t } from './localization';
import { setFaceData } from './store/actions';
import { type ImgData, ROUTES, type LoadMethod } from './constants';

import { FaceReadingWelcome } from './welcome';
import { FaceReadingCapture } from './capture';
import { FaceReadingAnalyzing } from './analyzing';
import { FaceReadingUpload } from './upload';
import { FaceReadingApprove } from './approve';
import ErrorPopup from './error-popup';

import { CustomStages } from './analyzing/components/descriptions/default';

import * as detector from './back-detector';

import type { ContentMode } from './types';

import classes from './style.module.scss';

interface FaceReadingProps {
  analyticsCategory: string;
  isSkipAvailable: boolean;
  isUploadAvailable: boolean;
  isApproveAvailable: boolean;
  isBackAvailable: boolean;
  cameraInitTimeout: number;
  analyzingDuration: number,
  welcomeType?: 'image' | 'static' | 'video',
  flareButtons?: boolean;
  onDone: (result: { imgUrl: string | null | undefined }) => void;
  onSkip: () => void;
  captureConfig?: FaceCapture;
  progressDescriptionType?: ProgressDescriptionType,
  welcomeInstructions?: boolean,
  customStages?: CustomStages,
  welcomeContent?: React.ReactNode,
  classNames?: Partial<Record<'welcome' | 'capture' | 'analyzing' | 'upload' | 'approve' | 'error', string>>
  contentMode?: ContentMode,
}

export const FaceReading: React.FC<FaceReadingProps> = ({
  classNames,
  analyticsCategory,
  isSkipAvailable,
  isUploadAvailable,
  isApproveAvailable,
  isBackAvailable,
  cameraInitTimeout,
  analyzingDuration,
  welcomeContent,
  flareButtons = true,
  welcomeType = 'image',
  onDone,
  onSkip,
  captureConfig,
  progressDescriptionType,
  welcomeInstructions,
  contentMode,
  customStages
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match = useResolvedPath("");
  const rootPath = match.pathname.replace(/\/$/, '');

  const [error, setError] = useState('');
  const [animation, setAnimation] = useState(false);

  const welcomeEventCategory = analyticsCategory + '_WELCOME';
  const uploadEventCategory = analyticsCategory + '_UPLOAD';
  const captureEventCategory = analyticsCategory + '_CAPTURE';
  const analyzingEventCategory = analyticsCategory + '_ANALYZING';
  const approveEventCategory = analyticsCategory + '_APPROVE';

  useEffect(() => {
    Analytics.trackEvent(welcomeEventCategory, EVENT_ACTION.OPEN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWelcomeCaptureClick = () => {
    Analytics.trackEvent(welcomeEventCategory, EVENT_ACTION.SUCCESS);
    navigate(rootPath + ROUTES.CAPTURE, { replace: true });
    Analytics.trackEvent(captureEventCategory, EVENT_ACTION.OPEN);
  };

  const handleWelcomeUploadClick = () => {
    Analytics.trackEvent(welcomeEventCategory, EVENT_ACTION.SUCCESS);
    navigate(rootPath + ROUTES.UPLOAD, { replace: true });
    Analytics.trackEvent(uploadEventCategory, EVENT_ACTION.OPEN);
  };

  const handleWelcomeSkipClick = () => {
    Analytics.trackEvent(welcomeEventCategory, EVENT_ACTION.SKIP);
    onSkip();
  };

  const handleApprovePhoto = (img: ImgData, type: LoadMethod) => {
    navigate(rootPath + ROUTES.APPROVE, { state: { img: img?.img, width: img?.width, height: img?.height, type, isFullScreen: img?.isFullScreen }, replace: true });
  }

  const handleDetectorSuccess = useCallback(({ img, width, height, isFullScreen }: ImgData) => {
    dispatch(setFaceData({ faceImg: img, faceImgDims: { width, height, isFullScreen: !!isFullScreen } }));
    navigate(rootPath + ROUTES.ANALYZING, { replace: true });
    setAnimation(true);
    Analytics.trackEvent(analyzingEventCategory, EVENT_ACTION.OPEN);

    return detector.process(img)
      .catch(() => null)
      .then((keypoints) => {
        if (!keypoints) {
          setAnimation(false);
          dispatch(setFaceData({ faceImg: '', faceImgDims: { width: 0, height: 0, isFullScreen: false } }));
          throw Error('not_face');
        } else {
          dispatch(setFaceData({ faceKeyPoints: keypoints, faceImgDims: { width, height, isFullScreen: !!isFullScreen } }));;
        }
      });
  }, [analyzingEventCategory, dispatch, navigate, rootPath]);

  const handleCaptureSuccess = useCallback((img: ImgData) => {
    Analytics.trackEvent(captureEventCategory, EVENT_ACTION.SUCCESS);
    handleDetectorSuccess(img).catch((err) => {
      Analytics.trackEvent(captureEventCategory, EVENT_ACTION.ERROR, { error: err?.message });
      setError(t('error_no_face'));
      navigate(rootPath + ROUTES.CAPTURE, { replace: true, state: { retake: true } });
    });
  }, [captureEventCategory, handleDetectorSuccess, navigate, rootPath]);

  const handleUploadSuccess = useCallback((img: ImgData) => {
    Analytics.trackEvent(uploadEventCategory, EVENT_ACTION.SUCCESS);
    handleDetectorSuccess(img).catch((err) => {
      Analytics.trackEvent(uploadEventCategory, EVENT_ACTION.ERROR, { error: err?.message });
      navigate(rootPath + ROUTES.UPLOAD, { replace: true, state: { src: img?.img } });
    });
  }, [handleDetectorSuccess, navigate, rootPath, uploadEventCategory]);

  const handleCaptureError = useCallback((err: Error) => {
    Analytics.trackEvent(captureEventCategory, EVENT_ACTION.ERROR, { error: err?.message });
    onSkip();
  }, [captureEventCategory, onSkip]);

  const handleAnalyzingDone = useCallback((result: { imgUrl: string | null | undefined }) => {
    setAnimation(false);
    setTimeout(() => {
      Analytics.trackEvent(analyzingEventCategory, EVENT_ACTION.SUCCESS);
      onDone(result);
    }, 2000);
  }, [setAnimation, onDone, analyzingEventCategory]);

  const handleErrorPopupClose = useCallback(() => {
    setError('');
  }, [setError]);

  const handleCaptureSkipClick = useCallback(() => {
    setError('');
    Analytics.trackEvent(captureEventCategory, 'try_later');
    onSkip();
  }, [captureEventCategory, onSkip]);

  const handleUploadCloseClick = useCallback(() => {
    Analytics.trackEvent(uploadEventCategory, EVENT_ACTION.CLOSE);
    navigate(match.pathname, { replace: true });
  }, [navigate, match.pathname, uploadEventCategory]);

  const handleUploadSkipClick = useCallback(() => {
    Analytics.trackEvent(uploadEventCategory, 'try_later');
    onSkip();
  }, [onSkip, uploadEventCategory]);

  return (
    <div className={classes.container}>
      <Routes>
        <Route path={ROUTES.UPLOAD} element={
          <FaceReadingUpload
            flare={flareButtons}
            className={classNames?.upload}
            onSuccess={isApproveAvailable ? handleApprovePhoto : handleUploadSuccess}
            onCloseClick={handleUploadCloseClick}
            onSkipClick={handleUploadSkipClick}
          />
        } />
        <Route path={ROUTES.CAPTURE} element={
          <FaceReadingCapture
            className={classNames?.capture}
            contentMode={contentMode}
            cameraInitTimeout={cameraInitTimeout}
            onError={handleCaptureError}
            onSuccess={isApproveAvailable ? handleApprovePhoto : handleCaptureSuccess}
            eventCategory={captureEventCategory}
            isBackAvailable={isBackAvailable}
            captureConfig={captureConfig}
          />
        } />
        <Route path={ROUTES.ANALYZING} element={
          <FaceReadingAnalyzing
            className={classNames?.analyzing}
            analyzingDuration={analyzingDuration}
            onDone={handleAnalyzingDone}
            progressDescriptionType={progressDescriptionType}
            customStages={customStages}
          />
        } />
        <Route path={ROUTES.APPROVE} element={
          <FaceReadingApprove
            flare={flareButtons}
            className={classNames?.approve}
            eventCategory={approveEventCategory}
            onCaptureSuccess={handleCaptureSuccess}
            onUploadSuccess={handleUploadSuccess}
            captureEventCategory={captureEventCategory}
            uploadEventCategory={uploadEventCategory}
          />
        } />
        <Route path={'*'} element={
          <FaceReadingWelcome
            flare={flareButtons}
            className={classNames?.welcome}
            type={welcomeType}
            isSkipAvailable={isSkipAvailable}
            isUploadAvailable={isUploadAvailable}
            welcomeContent={welcomeContent}
            welcomeInstructions={welcomeInstructions}
            contentMode={contentMode}
            onSkipClick={handleWelcomeSkipClick}
            onCaptureClick={handleWelcomeCaptureClick}
            onUploadClick={handleWelcomeUploadClick}
          />
        } />
      </Routes>
      {animation && <div className={classes.animation}></div>}
      <ErrorPopup
        className={classNames?.error}
        visible={!!error}
        message={error}
        onClose={handleErrorPopupClose}
        onSkipClick={handleCaptureSkipClick}
      />
    </div>
  );
};
