import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import { tm, T } from '@web-solutions/module-localization';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { Title, Section } from '@web-solutions/core/ui-elements';

import TrialReminder from '@web-solutions/core/payment/components/trial-reminder';
import PaymentDescription from '@web-solutions/core/payment/components/payment-description';
import { ProductDetails, selectSpecialOfferEndDate } from '@web-solutions/core/store/billing/selectors';

import { useIsSingleTrialPlanMode } from '@web-solutions/core/hooks/use-is-single-trial-plan-mode';

import { PlanType } from 'core/constants/remote-config';

import { FixedButton, type FixedButtonProps } from '../fixed-button';

import { PaymentSafe } from '../../containers/payment-safe';

import { ProductsTexts } from '..';

import { PolicyText } from './policy-text';
import { Item } from './item';
import { PdfItem } from './pdf-item';
import { Feature } from './feature';
import { Ticket } from './ticket';
import { TrialPlan } from './trial-plan';

import classes from './style.module.scss';
import { SinglePlan } from './single-plan';

interface PlansProps {
  activeProduct: ProductDetails,
  products: ProductDetails[],
  productsTexts: ProductsTexts[];
  isPolicyPaymentVisible?: boolean;
  onProductClick: (p: ProductDetails) => void,
  onFixedButtonClick?: FixedButtonProps['onClick'],
  onPayPalSubmit?: FixedButtonProps['onPayPalSubmit'],
  showTerms?: boolean,
  subtitle?: React.ReactNode,
  className?: string,
}

export const Plans: React.FC<PlansProps> = ({
  activeProduct,
  products,
  productsTexts,
  subtitle,
  showTerms = true,
  isPolicyPaymentVisible = true,
  className,
  onProductClick,
  onFixedButtonClick,
  onPayPalSubmit,
}) => {
  const plansSectionId = 'plans';
  const productsListId = 'products-list';

  const {
    buttonsConfigs,
    productsTitle,
    showPaymentSafeAtPlans,
    isTrialPeriodReminder,
    specialOffer,
    planType,
    plansFeatures,
    showTermsAtPaymentModal,
  } = useRemoteConfig();

  const specialOfferEndDate = useSelector(selectSpecialOfferEndDate);

  const {
    trialPrice,
    isAvailable: isSingleTrialPlanMode
  } = useIsSingleTrialPlanMode();

  const handleItemClick = useCallback(
    (selectedProduct: ProductDetails) => {
      Analytics.trackEvent('product', 'selected', { selectedProductId: selectedProduct.id });
      onProductClick(selectedProduct);
    },
    [onProductClick],
  );

  const renderItems = useCallback(
    (type: PlanType) => products.map((product) => {
      const currentProductTexts = productsTexts.find((text) => text.id === product.id)!;

      switch (type) {
        case PlanType.PDF:
          return (
            <PdfItem
              key={product.id}
              isActive={activeProduct.id === product.id}
              onClick={handleItemClick}
              product={product}
            />
          );
        case PlanType.TICKET:
          return (
            <Ticket
              key={product.id}
              isActive={activeProduct.id === product.id}
              onClick={handleItemClick}
              product={product}
              titleText={currentProductTexts.titleText}
              subTitleText={currentProductTexts.subTitleText}
              perText={currentProductTexts.perText}
              perTitle={currentProductTexts.perTitle}
            />
          )
        //@ts-ignore
        case PlanType.TRIAL: {
          if (trialPrice) {
            return (
              <TrialPlan
                key={product.id}
                product={product}
                onClick={handleItemClick}
              />
            )
          }
        }
        // eslint-disable-next-line no-fallthrough
        default:
          return (
            <Item
              key={product.id}
              isActive={activeProduct.id === product.id}
              onClick={handleItemClick}
              product={product}
              titleText={currentProductTexts.titleText}
              subTitleText={currentProductTexts.subTitleText}
              perText={currentProductTexts.perText}
              perTitle={currentProductTexts.perTitle}
              className={className}
            />
          )

      }
    }),
    [trialPrice, products, activeProduct.id, handleItemClick, productsTexts],
  );

  const renderPlanFeatures = useCallback(() => plansFeatures.map((feature) => (
    <Feature text={feature} />
  )), [plansFeatures])

  const title = specialOfferEndDate
    ? <T k={'core.special_offer.products'} tOptions={{ discount: specialOffer.discount }} />
    : tm(productsTitle, '');

  return (
    <Section className={classNames(classes.wrap)} id={plansSectionId}>
      {products.length === 1 && planType === PlanType.SINGLE ?
        <SinglePlan product={products[0]} />
        :
        <>
          {!!title && <Title level="h2">{title}</Title>}
          {!!plansFeatures?.length && <div className={classes.plansFeatures}>{renderPlanFeatures()}</div>}
          {subtitle}
          <div className={classes.container}>
            <ul className={classes.productsList} id={productsListId}>{renderItems(planType)}</ul>
          </div>
        </>
      }

      {
        (isTrialPeriodReminder && !activeProduct.isOneTimePurchase) && <TrialReminder className={classes.reminder} />
      }

      {!!onFixedButtonClick && !!onPayPalSubmit &&
        <div className={classes.fixedBtnWrap}>
          <FixedButton
            id={productsListId}
            onlyFixed={isSingleTrialPlanMode}
            activeProduct={activeProduct}
            buttonsConfigs={buttonsConfigs}
            onClick={onFixedButtonClick}
            onPayPalSubmit={onPayPalSubmit}
          />
        </div>

      }

      {isPolicyPaymentVisible &&
        <>
          <PolicyText />
          {
            showTerms && !showTermsAtPaymentModal &&
            <PaymentDescription
              isShort
              activeProduct={activeProduct}
            />
          }
          {showPaymentSafeAtPlans &&
            <PaymentSafe isModal={false} />
          }
        </>
      }

    </Section>
  );
};
