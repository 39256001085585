import _findIndex from 'lodash/findIndex';
import _omit from 'lodash/omit';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import type { ThunkAction } from 'redux-thunk';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Analytics from '@web-solutions/module-analytics';

import { createCustomer, setPurchase } from '@web-solutions/core/store/billing/actions';

import { selectPaymentProject, selectPaymentSystem, } from '@web-solutions/core/store/billing/selectors';
import { remoteConfigSelector } from '@web-solutions/core/store/remote-config/selectors';

import { REDIRECT_TO_BROWSER, doRedirectToBrowser } from '@web-solutions/core/utils/redirect-to-browser';
import { stringifyUrlParams } from '@web-solutions/core/utils/url-sync';

import type { Purchase } from '@web-solutions/core/interfaces/billing';

import { downloadApp } from 'src/store/app/actions';

import { SUBSCRIPTION_NAMES } from 'src/constants/screens';
import { ROUTES, SUBSCRIPTION, SCREENS_WITH_AUTH } from 'src/constants/routes';

import { processEmail } from '../profile/actions';

import { selectFlow } from './selectors';

import { routingSlice } from '.';

export const { setCompleted, setStep, setStartRoute } = routingSlice.actions;

export const init = ({ linkAuthResult }: { linkAuthResult?: string } = {}): ThunkAction<Promise<any>, any, unknown, any> => (dispatch, getState) => {
  const { redirectToBrowser } = remoteConfigSelector(getState());
  const {
    billing: { purchase }
  } = getState();

  const flow = selectFlow(getState());

  if (redirectToBrowser === REDIRECT_TO_BROWSER.ENABLED) {
    doRedirectToBrowser(redirectToBrowser);
  }

  const params = queryString.parse(window.location.search);

  if (params.completed !== undefined) {
    dispatch(setCompleted(true));
  }

  if (params.purchased !== undefined && !purchase) {
    dispatch(setPurchase({} as Purchase));
  }

  if (params.email) {
    dispatch(processEmail(params.email as string));
  }

  const {
    routing: { isCompleted }
  } = getState();


  if (isCompleted) {
    if (params.without_sub !== undefined) {
      dispatch(setStartRoute(ROUTES.SUCCESS));
    } else {
      const startRoute = dispatch(getRouteAfterFlow());
      dispatch(setStartRoute(startRoute));
    }
  } else {
    if (linkAuthResult || params.auth === null) {
      createBrowserHistory()
        .replace(queryString.stringifyUrl({
          url: window.location.pathname,
          query: _omit(params, ['auth']),
        }));
      const index = _findIndex(flow, r => SCREENS_WITH_AUTH.includes(r));
      dispatch(setStep(index));
    }
    if (params.without_sub !== undefined) {
      dispatch(setStartRoute(ROUTES.SUCCESS));
    } else {
      const startRoute = ROUTES[flow[0].toUpperCase() as keyof typeof ROUTES];
      dispatch(setStartRoute(startRoute));
    }
  }

  return Promise.resolve();
};

export const getRouteAfterFlow = (): ThunkAction<string, any, unknown, any> => (dispatch, getState) => {
  const {
    remoteConfig: { presummaryStack },
    billing: { purchased },
  } = getState();
  const params = queryString.parse(window.location.search);
  const isRedirectTocompatibility = params.compatibility_hardcover === null || params.compatibility === null;

  if (purchased && !isRedirectTocompatibility) {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.SUCCESS];
  } else if (presummaryStack?.length) {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.PRESUMMARY];
  } else {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.MAIN];
  }
};

export const beforeRedirectWithoutSubscription = createAsyncThunk(
  `${routingSlice.name}/beforeRedirectWithoutSubscription`,
  async ({ analytics }: { analytics: string }, { dispatch, getState }) => {
    const state: any = getState();

    const { remoteConfig: { redirectToAppWithoutSubscription } } = state

    Analytics.trackEvent(analytics, 'redirect');
    Analytics.flush();

    const paymentSystem = selectPaymentSystem(state);
    const paymentProject = selectPaymentProject(state);

    try {
      await dispatch(createCustomer({ paymentSystem, paymentProject }));
    } catch (ex) { }

    if (redirectToAppWithoutSubscription?.autoRedirect) {
      dispatch(downloadApp());
    }

    stringifyUrlParams({ without_sub: null });
  });
