import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import Analytics from '@web-solutions/module-analytics';

import { Preloader, LinkButton } from '@web-solutions/core/ui-elements';

import { t } from '../../localization';

import { useReturnToApp } from '../hooks/use-return-to-app';
import { cancelSubscription, useSliceDispatch, selectPending } from '../slice';

import { ROUTES } from '../routes';

import { ReactComponent as Cancel } from './icons/cancel.svg';
import { ReactComponent as Warning } from './icons/warning.svg';

import classes from './style.module.scss';

const tKey = 'terminate.confirm';

export const TerminateConfirmScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useSliceDispatch();
  const isPending = useSelector(selectPending);

  const { available, handleReturnAppClick } = useReturnToApp({ category: 'terminate_confirm' })

  const features = useMemo(() => t(`${tKey}.features`, { returnObjects: true }) as any as Array<{ title: string, subtitle: string }>, []);
  const p = queryString.parse(window.location.search);
  const source = p?.source;

  useEffect(() => {
    Analytics.trackEvent('terminate_confirm', 'open', source ? { source } : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubscriptionCancelClick = async () => {
    Analytics.trackEvent('terminate_confirm', 'click', source ? { source } : undefined);
    const params = queryString.parse(window.location.search);
    const search = queryString.stringify({ idfm: params.idfm });

    dispatch(cancelSubscription())
      .unwrap()
      .then(() => {
        Analytics.trackEvent('terminate_confirm', 'success', source ? { source } : undefined);
        navigate({
          pathname: ROUTES.SUCCESS,
          search: search
        })
      }, err => {
        Analytics.trackEvent('terminate_confirm', 'error', { message: err.message });
        navigate(ROUTES.ERROR, { state: { code: err.code } });
      });
  };

  if (isPending) {
    return <Preloader />;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.header__blur} />
        <div className={classes.header__title}>{t(`${tKey}.main_title`)}</div>
      </div>
      <div className={classes.content}>
        <div className={classes.warning}>
          <Warning className={classes.warning__icon} />
          <p className={classes.warning__title}>{t(`${tKey}.warning`)}</p>
        </div>
        {!!features?.length && (
          <div className={classes.features}>
            <div className={classes.features__title}>{t(`${tKey}.features_title`)}</div>
            {
              features.map(item => (
                <div className={classes.feature} key={item.title}>
                  <Cancel className={classes.feature__icon} />
                  <div>
                    <p className={classes.feature__title}>{item.title}</p>
                    <p className={classes.feature__subtitle}>{item.subtitle}</p>
                  </div>
                </div>
              ))
            }
          </div>
        )}
        {
          available && (
            <LinkButton
              className={classes.link}
              onClick={handleReturnAppClick}
            >
              {t(`${tKey}.link`)}
            </LinkButton>
          )
        }
        <button
          className={classes.button}
          onClick={handleSubscriptionCancelClick}
        >
          {t(`${tKey}.button`)}
        </button>
      </div>
    </div>
  )
};
