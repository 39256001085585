import React from 'react';

import { useHeight } from '@web-solutions/core/hooks/use-height';
import { Preloader } from '@web-solutions/core/ui-elements';

import { Main } from './screens/main';

import classes from './style.module.scss';
import { AnswerProcessorParams, Question } from './types';

interface Props {
  isApiReady: boolean,
  isLoadedApp: boolean,
  Questions: Question[],
  onAnswer: (id: AnswerProcessorParams, screen: string) => void;
  onFinish: () => void;
}

export const ChatFlow: React.FC<Props> = ({
  isApiReady,
  isLoadedApp,
  Questions,
  onAnswer,
  onFinish,
}) => {

  const containerHeight = useHeight();

  return (
    <>
      {!(isApiReady && isLoadedApp) ?
        <Preloader className={classes.preloader} />
        :
        <div className={classes.bg_wrap}>
          <div style={{ minHeight: containerHeight }} className={classes.wrapper}>
            <Main Questions={Questions} onFinish={onFinish} onAnswer={onAnswer} />
          </div>
        </div>
      }
    </>
  );
}
