import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import EmailForm from '@web-solutions/core/containers/email/components/email-form';
//@ts-ignore
import { useEmail } from '@web-solutions/core/hooks/use-email';
import { t } from '@web-solutions/module-localization';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import Analytics from '@web-solutions/module-analytics';

import { useBaseAnswer } from "../../../hooks/use-base-answer"
import type { Answer, AnswerProcessorParams, BaseAnswerProps } from "../../../types";
import { BaseAnswer } from "../../base-answer"
import { UserAnswer } from "../../user-answer";
import { Button } from '../../button';

import classes from './style.module.scss';

interface InputProps extends BaseAnswerProps {
  data: {
    answer: Answer[];
    skipText?: string;
  };
  onSuccess: (id: AnswerProcessorParams, screen: string) => void;
}

export const EmailAnswer: React.FC<InputProps> = ({
  messages,
  postMessages,
  withBoost,
  analyticsCategory,
  isActive,
  onNextMessage,
  onSuccess,
}) => {

  //@ts-ignore
  const email = useSelector((state) => state.profile.email);
  const [value, isValidValue, setValue] = useEmail(email || '');

  const [submitted, setSubmitted] = useState(false);

  const { display, handleNextMessage } = useBaseAnswer({ analyticsCategory });

  const handleSubmit = () => {
    if (!postMessages?.length) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: email });
      onSuccess({ value }, analyticsCategory);
    }
    setSubmitted(true);
    handleNextMessage();
    onNextMessage && onNextMessage();
  }

  const handleFinish = useCallback((finished?: boolean) => {
    handleNextMessage();
    if (finished) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: email });
      onSuccess({ value }, analyticsCategory);
    }
  }, [handleNextMessage, onSuccess, value, analyticsCategory, email])


  const handleNewMessage = () => {
    handleFinish();
    onNextMessage && onNextMessage()
  }

  return <BaseAnswer
    analyticsCategory={analyticsCategory}
    messages={messages}
    postMessages={postMessages}
    display={display}
    isActive={isActive}
    withBoost={withBoost}
    onNextMessage={handleNewMessage}
  >
    {display >= messages.length &&
      <>
        {submitted ?
          <UserAnswer>
            {value}
          </UserAnswer>
          :
          <>
            <div className={classes.wrapper}>
              <EmailForm
                value={value}
                isValid={isValidValue}
                onChange={setValue}
                onSubmit={handleSubmit}
                placeholder={t(`chat_flow.${analyticsCategory}.placeholder`)}
              />
              <Button disabled={!isValidValue} onClick={handleSubmit} />
            </div>
          </>
        }
      </>
    }
  </BaseAnswer>
}