import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { t } from '@web-solutions/module-localization';
import { LinkButton } from '@web-solutions/core/ui-elements';
import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import { PlacePicker } from '@web-solutions/core/containers/place-picker';

import { useBaseAnswer } from "../../../hooks/use-base-answer"
import type { Answer, AnswerProcessorParams, BaseAnswerProps } from "../../../types";
import { BaseAnswer } from "../../base-answer"
import { UserAnswer } from "../../user-answer";
import { Button } from '../../button';

import classes from './style.module.scss';

interface InputProps extends BaseAnswerProps {
  data: {
    answer: Answer[];
    skipText?: string;
  };
  onSuccess: (id: AnswerProcessorParams, screen: string) => void;
}

export const PlaceAnswer: React.FC<InputProps> = ({
  messages,
  postMessages,
  data,
  analyticsCategory,
  withBoost,
  isActive,
  onNextMessage,
  onSuccess,
}) => {

  const { skipText } = data;

  const [submitted, setSubmitted] = useState(false);
  const [skiped, setSkiped] = useState(false);

  const { display, handleNextMessage } = useBaseAnswer({ analyticsCategory });

  const birthPlace = useSelector((state: any) => state.profile.birthPlace);
  const approximateLocation = useSelector((state: any) => state.profile.approximateLocation);

  const [userValue, setUserValue] = useState(birthPlace || approximateLocation);

  const isValidValue = !!userValue?.label && !!userValue?.lat;

  const handleSubmit = () => {
    if (!postMessages?.length) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { ...userValue });;
      onSuccess({ ...userValue }, analyticsCategory);
    }
    setSubmitted(true);
    handleNextMessage();
    onNextMessage && onNextMessage();
  }

  const handleFinish = useCallback((finished?: boolean) => {
    handleNextMessage();
    if (finished) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { ...userValue });
      onSuccess({ ...userValue }, analyticsCategory);
    }
  }, [handleNextMessage, onSuccess, userValue, analyticsCategory])

  const handleSkip = () => {
    if (!postMessages?.length) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SKIP);
      onSuccess({ value: '' }, analyticsCategory);
    }
    setSkiped(true);
    handleNextMessage();
  };


  const handleChange = useCallback((value: any) => {
    setUserValue(value);
  }, [setUserValue]);

  const handleFocus = () => {
    if (userValue === approximateLocation) {
      setUserValue(null);
    }
  }


  const handleNewMessage = () => {
    handleFinish();
    onNextMessage && onNextMessage()
  }

  return <BaseAnswer
    analyticsCategory={analyticsCategory}
    messages={messages}
    postMessages={postMessages}
    display={display}
    isActive={isActive}
    withBoost={withBoost}
    onNextMessage={handleNewMessage}
  >
    {display >= messages.length &&
      <>
        {skiped ?
          <UserAnswer>
            {skipText}
          </UserAnswer>
          :
          <>
            {submitted ?
              <UserAnswer>
                {userValue?.label}
              </UserAnswer>
              :
              <div className={classes.wrapper}>
                <div className={classes.picker}>
                  <PlacePicker
                    placeholder={t(`chat_flow.${analyticsCategory}.placeholder`)}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    value={userValue}
                    className={classes.wrap}
                    onBlur={() => { }}
                    autoFocus={false}
                    isFocused
                  />
                  <Button disabled={!isValidValue} onClick={handleSubmit} />
                </div>
                {skipText && <LinkButton className={classes.skip} onClick={handleSkip}>{skipText}</LinkButton>}
              </div>
            }
          </>
        }
      </>
    }
  </BaseAnswer>
}