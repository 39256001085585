import { useState, useCallback, useEffect } from 'react';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { BaseAnswer } from '../base-answer';
import type { Answer, AnswerProcessorParams, BaseAnswerProps } from '../../types';
import { AnswerButton } from '../answer-button';
import { useBaseAnswer } from '../../hooks/use-base-answer';

interface MultiuAnswerProps extends BaseAnswerProps {
  data: {
    answer: Answer[];
    skipText?: string;
  };
  onSuccess: (id: AnswerProcessorParams, screen: string) => void;
}

export const MultiAnswer: React.FC<MultiuAnswerProps> = ({
  messages,
  postMessages,
  data,
  withBoost,
  analyticsCategory,
  isActive,
  onNextMessage,
  onSuccess,
}) => {
  const { answer } = data;

  const { display, handleNextMessage } = useBaseAnswer({ analyticsCategory });

  const [activeAnswer, setActiveAnswer] = useState<null | string>(null)

  const handleSuccess = (id: string) => {
    if (!postMessages?.length) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: id });
      onSuccess({ value: id }, analyticsCategory);
    }
    setActiveAnswer(id);
    handleNextMessage();
    onNextMessage && onNextMessage();
  }

  useEffect(() => {
    if (display === messages.length && answer.length === 0) {
      handleSuccess('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer.length, display, messages.length])

  const handleFinish = useCallback((finished?: boolean) => {
    handleNextMessage();
    if (finished) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: activeAnswer });
      onSuccess({ value: activeAnswer! }, analyticsCategory);
    }
  }, [handleNextMessage, onSuccess, activeAnswer, analyticsCategory])

  const handleNewMessage = () => {
    handleFinish()
    onNextMessage && onNextMessage()
  }

  return <BaseAnswer
    isActive={isActive}
    analyticsCategory={analyticsCategory}
    messages={messages}
    postMessages={postMessages}
    display={display}
    withBoost={withBoost}
    onNextMessage={handleNewMessage}
  >
    {display >= messages.length && answer.map((item) => {
      if (activeAnswer !== null && activeAnswer !== item.id) {
        return null
      }

      return <AnswerButton
        id={item.id}
        key={item.id}
        text={item.text}
        onClick={handleSuccess}
      />
    })}
  </BaseAnswer>
}