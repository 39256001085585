import _mapValues from 'lodash/mapValues';

import {
  QUIZ_NAMES,
  SUBSCRIPTION_NAMES,
  ADDITIONAL_SCREENS_NAMES,
  AfterPurchaseRoutes,
  type QuizNameKeys,
  type AfterPurchaseStep,
  type QuizNamesValues,
} from './screens';

export const SUBSCRIPTION = {
  [SUBSCRIPTION_NAMES.PRESUMMARY]: '/subscription/presummary',
  [SUBSCRIPTION_NAMES.MAIN]: '/subscription/main',
  [SUBSCRIPTION_NAMES.SUCCESS]: '/subscription/success',
  [SUBSCRIPTION_NAMES.COMPATIBILITY]: '/subscription/improve-reports-compatibility',
  [SUBSCRIPTION_NAMES.BIRTH_CHART]: '/subscription/improve-reports-birth-chart',
  [SUBSCRIPTION_NAMES.NUMEROLOGY_ANALYSIS]: '/subscription/improve-reports-numerology-analysis',
  [SUBSCRIPTION_NAMES.GUIDES]: '/subscription/guides',
  [SUBSCRIPTION_NAMES.ASTROLOGERS_SINGLE_PACK]: '/subscription/single-pack-offer',
  [SUBSCRIPTION_NAMES.ASTROLOGERS_PACKS]: '/subscription/astrologers-packs-offer',
  [SUBSCRIPTION_NAMES.COMPATIBILITY_BIRTH_CHART_ULTRA]: '/subscription/compatibility_birth_chart_ultra',
  [SUBSCRIPTION_NAMES.UPSALES_BASKET]: '/subscription/upsales-basket',
  [SUBSCRIPTION_NAMES.COMPATIBILITY_HARDCOVER]: '/subscription/compatibility-hardcover',
  [ADDITIONAL_SCREENS_NAMES.MY_ACCOUNT]: '/my-account',
};

export const QUIZ: { [key in QuizNameKeys]: string } = _mapValues(QUIZ_NAMES, (v) => '/' + v.toLowerCase() + '/*');

export const ROUTES = {
  ...QUIZ,
  ...SUBSCRIPTION,
};

export const SCREENS_WITHOUT_PROGRESSBAR = [
  QUIZ_NAMES.QUIZ_MAGIC,
  QUIZ_NAMES.QUIZ_REPORT_LOADER,
  QUIZ_NAMES.QUIZ_EMAIL,
  QUIZ_NAMES.WARMUP_LOADER,
  QUIZ_NAMES.QUIZ_TRIAL,
  QUIZ_NAMES.QUIZ_MAILING,
  QUIZ_NAMES.QUIZ_SURVEY,
  QUIZ_NAMES.CHAT_FLOW,
  QUIZ_NAMES.CHAT_FLOW_BRANCHING,
  QUIZ_NAMES.QUIZ_TRANSITION_LOADER_ANIMATION,
  QUIZ_NAMES.QUIZ_TRANSITION_REVIEWS,
  QUIZ_NAMES.QUIZ_ASTROLOGER_MINUTES,
  QUIZ_NAMES.QUIZ_ASTROLOGER_MINUTES_POPUP,
  QUIZ_NAMES.QUIZ_SELECT_PLANS_BY_TRIAL,
  QUIZ_NAMES.QUIZ_GIFT_BOX,
];

export const SCREENS_WITH_AUTH: QuizNamesValues = [
  QUIZ_NAMES.QUIZ_EMAIL,
];

const AFTER_PURCHASE: { [key in AfterPurchaseStep]: void } = {
  [SUBSCRIPTION_NAMES.GUIDES]: undefined,
  [SUBSCRIPTION_NAMES.COMPATIBILITY]: undefined,
  [SUBSCRIPTION_NAMES.BIRTH_CHART]: undefined,
  [SUBSCRIPTION_NAMES.NUMEROLOGY_ANALYSIS]: undefined,
  [SUBSCRIPTION_NAMES.COMPATIBILITY_BIRTH_CHART_ULTRA]: undefined,
  [SUBSCRIPTION_NAMES.ASTROLOGERS_PACKS]: undefined,
  [SUBSCRIPTION_NAMES.ASTROLOGERS_SINGLE_PACK]: undefined,
  [SUBSCRIPTION_NAMES.UPSALES_BASKET]: undefined,
  [SUBSCRIPTION_NAMES.COMPATIBILITY_HARDCOVER]: undefined,
}

export const AFTER_PURCHASE_ROUTES = Object.keys(AFTER_PURCHASE) as AfterPurchaseRoutes;

