import React, { useCallback } from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface AutocompleteProps {
  options: string[],
  onSelect: (value: string) => {} | void,
  optionClassName?: string,
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  options,
  onSelect,
  optionClassName,
}) => {
  const handleItemClick = useCallback((e: React.SyntheticEvent, option: string) => {
    e.preventDefault();
    onSelect(option);
  }, [onSelect]);

  return (
    <ul className={classes.helper}>
      {options.map((option) => (
        <li
          key={option}
          className={classNames(classes.helper_item, optionClassName)}
        >
          <button
            type="button"
            onClick={(e) => handleItemClick(e, option)}
          >
            {option}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Autocomplete;
