import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TarotCardV2 } from 'src/types/tarot'

export interface TarotState {
  cards: TarotCardV2[] | null;
}

const initialState: TarotState = {
  cards: null
};

export const tarotSlice = createSlice({
  name: 'tarot',
  initialState,
  reducers: {
    setTarotCards: (state, action: PayloadAction<TarotCardV2[]>) => {
      state.cards = action.payload;
    },
  }
})