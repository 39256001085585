import { DateAnswer } from "../components/dropdown-answers/date";
import { TimeAnswer } from "../components/dropdown-answers/time";
import { GenericAnswer } from "../components/generic-answer";
import { PlaceAnswer } from "../components/inputs/birth-place";
import { EmailAnswer } from "../components/inputs/email";
import { TextAnswer } from "../components/inputs/text";
import { MultiAnswer } from "../components/multi-answer";
import { SingleAnswer } from "../components/single-answer";
import { TransitionAnswer } from "../components/transition";
import { QuestionTypes } from "../types";

export const ANSWER_COMPONENTS = {
  [QuestionTypes.SINGLE]: SingleAnswer,
  [QuestionTypes.MULTI]: MultiAnswer,
  [QuestionTypes.TIME]: TimeAnswer,
  [QuestionTypes.DATE]: DateAnswer,
  [QuestionTypes.TEXT]: TextAnswer,
  [QuestionTypes.PLACE]: PlaceAnswer,
  [QuestionTypes.EMAIL]: EmailAnswer,
  [QuestionTypes.COMPONENT]: GenericAnswer,
  [QuestionTypes.TRANSITION]: TransitionAnswer,
}