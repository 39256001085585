import { useState } from 'react';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import Analytics from '@web-solutions/module-analytics';

import { BaseAnswer } from '../base-answer';
import type { Answer, AnswerProcessorParams, BaseAnswerProps } from '../../types';
import { useBaseAnswer } from '../../hooks/use-base-answer';
import { UserAnswer } from '../user-answer';

import classes from './style.module.scss';

interface GenericAnswerProps extends BaseAnswerProps {
  data: {
    answer: Answer[];
    skipText?: string;
    answerComponent?: <T>(props: T) => JSX.Element,
  };
  onSuccess: (id: AnswerProcessorParams, screen: string) => void;
}

export const GenericAnswer: React.FC<GenericAnswerProps> = ({
  messages,
  postMessages,
  data,
  withBoost,
  analyticsCategory,
  isActive,
  onNextMessage,
  onSuccess,
}) => {
  const { answerComponent } = data;

  const { display, handleNextMessage } = useBaseAnswer({ analyticsCategory });

  const [value, setValue] = useState<React.ReactNode | null>(null);

  const handleSuccess = (data: any) => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS);
    setValue(data?.value);
    onSuccess(data, analyticsCategory);
    handleNextMessage();
    onNextMessage && onNextMessage();
  }

  const handleError = (err: { error: unknown, message: string }) => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.ERROR, { error: err.error });
    setValue(<p className={classes.error}>{err.message}</p>);
    onSuccess({}, analyticsCategory);
    handleNextMessage();
  }

  const handleSkip = (message: string) => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SKIP);
    setValue(<p className={classes.error}>{message}</p>);
    onSuccess({}, analyticsCategory);
    handleNextMessage();
  }

  const handleNewMessage = () => {
    handleNextMessage();
    onNextMessage && onNextMessage()
  }

  return <BaseAnswer
    analyticsCategory={analyticsCategory}
    messages={messages}
    postMessages={postMessages}
    display={display}
    isActive={isActive}
    withBoost={withBoost}
    onNextMessage={handleNewMessage}
  >
    {display >= messages.length && <>
      {value ?
        <UserAnswer className={classes.reset}>
          {value}
        </UserAnswer>
        :
        <>
          {answerComponent && answerComponent({ onSuccess: handleSuccess, onError: handleError, onSkipClick: handleSkip })}
        </>
      }
    </>
    }
  </BaseAnswer>
}