export interface Message {
  type: string;
  node?: React.ReactNode;
  text: string;
}

export interface Question {
  type: QuestionTypes,
  analyticsCategory: string,
  messages: Message[],
  postMessages?: Message[],
  withBoost?: boolean,
  data: {
    answers: Answer[],
    answerComponent?: <T>(props: T) => JSX.Element,
    skipText?: string,
  }
}

export interface Answer {
  text: string,
  id: string
}

export type AnswerProcessorParams = Record<"value" | string, string>;

export const enum QuestionTypes {
  SINGLE = 'single',
  MULTI = 'multi',
  TIME = 'time',
  DATE = 'date',
  TEXT = 'text',
  PLACE = 'place',
  EMAIL = 'email',
  COMPONENT = 'component',
  TRANSITION = 'transition',
};

export type ChatbotData = {
  id: string,
  name: string,
  avatarLink: string,
}

export interface BaseAnswerProps {
  messages: Message[];
  analyticsCategory: string;
  postMessages?: Message[];
  withBoost?: boolean;
  isActive: boolean;
  onNextMessage: (finished?: boolean) => void;
}