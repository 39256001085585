import classNames from 'classnames';

import classes from './style.module.scss';

interface SelectProps {
  name: string,
  placeholder?: string;
  options: {
    value: string;
    selected?: boolean;
  }[];
  className?: string;
}

export const Select: React.FC<SelectProps> = ({ name, placeholder, options, className }) => {
  return <select name={name} className={classNames(classes.wrapper, className, { [classes.placeholder]: !!placeholder })}>
    {placeholder && <option value="" disabled selected hidden>{placeholder}</option>}
    {
      options.map(({ value, selected }) => {
        return <option value={value} selected={selected} key={value}>{value}</option>
      })
    }
  </select>
}