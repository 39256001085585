import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { Title, Button, Text } from '@web-solutions/core/ui-elements';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { useHeight } from '@web-solutions/core/hooks/use-height';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { goAppLink, setHasBeenOnEmailCancelPage, selectSubscription, useSliceDispatch } from '../../slice';

import { t, tm, TM, T } from '../../../localization';

import { ReactComponent as IconClose } from '../../components/icons/close-icon.svg';

import { useManageContext } from '../../hooks/use-manage-context';

import classes from './style.module.scss';

const tKey = 'manage.email_cancel';

const category = 'manage_email_cancel';

export const EmailCancelPage: React.FC = () => {
  const { mngSubEmailCancelPage } = useRemoteConfig();
  const { emailCancelContent } = useManageContext();
  const dispatch = useSliceDispatch();
  const sub = useSelector(selectSubscription);
  const containerHeight = useHeight();

  const isSimpleMode = mngSubEmailCancelPage?.isSimpleMode

  const isJaLng = navigator.language.split("-")[0] === 'ja';

  useEffect(() => {
    dispatch(setHasBeenOnEmailCancelPage(true));
    Analytics.trackEvent(category, EVENT_ACTION.OPEN);
  }, [dispatch]);

  const handleCloseClick = () => {
    Analytics.flush();
    Analytics.trackEvent(category, EVENT_ACTION.CLOSE);
    dispatch(goAppLink());
  };

  return (
    <div
      className={classNames(classes.wrap, {
        [classes.small_text]: isJaLng,
        [classes.simple]: isSimpleMode,
      })}
      style={{ minHeight: containerHeight }}>
      {!isSimpleMode &&
        <div>
          <Title level="h1" className={classes.title}>
            {tm(mngSubEmailCancelPage?.title, `${tKey}.title`)}
          </Title>
          <Text className={classes.text}>
            <TM
              k={mngSubEmailCancelPage?.subtitle}
              defaultKey={`${tKey}.subtitle`}
              tOptions={{ email: sub?.email, supportEmail: process.env.REACT_APP_SUPPORT_EMAIL }}
              components={{ span: <span className={classes.dark} /> }}
            />
          </Text>
        </div>
      }
      <button type="button" onClick={handleCloseClick} className={classes.close}>
        <IconClose width="16px" height="16px" />
      </button>
      <div className={classes.context_wrap}>
        {emailCancelContent}
        {
          isSimpleMode && <>
            <Title level="h1" className={classes.title}>
              <TM k={mngSubEmailCancelPage?.title} defaultKey={`${tKey}.title`}/>
            </Title>
            <Text className={classes.text}>
              <TM
                k={mngSubEmailCancelPage?.subtitle}
                defaultKey={`${tKey}.subtitle`}
                tOptions={{ email: sub?.email, supportEmail: process.env.REACT_APP_SUPPORT_EMAIL }}
                components={{ span: <span className={classes.dark} /> }}
              />
            </Text>
          </>
        }
      </div>
      {!isSimpleMode && <Text className={classNames(classes.text, classes.dark)}>{t(`${tKey}.text`)}</Text>
      }
      <div className={classes.note_block}>
        <Text className={classes.note}>
          <T
            k={`${tKey}.note`}
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={{ email: <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className={classes.email} /> }}
          />
        </Text>
      </div>

      <Button
        onClick={handleCloseClick}
        type="button"
        className={classes.bottom_button}
        title={t(`${tKey}.button_title`)}
      />
    </div>
  );
};
